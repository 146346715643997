import { useDispatch } from "react-redux";
import { useState } from "react";
import BreadCrumb from "../../components/breadcrumb";
import { Formik, Form } from "formik";
import Button from "../../components/button";
import withRouter from "../../services/withrouter";
import { CircularProgress, InputLabel } from "@mui/material";
import DaterangeSelector from "../../components/daterange-selector";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { getStats } from "./store/aiStatsSlice";

const StatsView = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [statsData, setStatsData] = useState({});

  const initialValues = {
    dateFrom: "",
    dateTo: "",
  };

  return (
    <>
      {/* BEGIN #content  */}
      <div id="content" className="app-content">
        <BreadCrumb />
        <h1 className="page-header">AI Stats</h1>
        <hr className="mb-px" />

        <div id="basicTable" className="mb-5">
          <div className="row flex justify-between">
            <div className="w-full">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    setLoading(true);
                    dispatch(
                      getStats({
                        dateFrom: dayjs(values.dateFrom).format("YYYY-MM-DD"),
                        dateTo: dayjs(values.dateTo).format("YYYY-MM-DD"),
                      })
                    ).then((result) => {
                      if (result?.payload?.data) {
                        setStatsData(result.payload.data);
                      }

                      setLoading(false);
                    });
                  }}
                >
                  {(formik) => (
                    <Form noValidate autoComplete="off">
                      <div className="relative mb-5 flex px-2 py-2 bg-black/20">
                        <div className="w-12/12">
                          <InputLabel>{"Date from"}</InputLabel>
                          <DaterangeSelector
                            fullWidth
                            variant="standard"
                            name="dateFrom"
                            onlyDate
                          />
                        </div>
                        <div className="w-12/12 ml-3">
                          <InputLabel>{"Date to"}</InputLabel>
                          <DaterangeSelector
                            fullWidth
                            variant="standard"
                            name="dateTo"
                            onlyDate
                          />
                        </div>
                      </div>

                      <div className="text-left">
                        <Button
                          size="sm"
                          label="Search"
                          type="submit"
                          disabled={loading}
                          // onClick={() => {
                          //   formik.submitForm();
                          // }}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>

                <div className="relative bg-black/20 mt-4 p-4 text-sm font-light border border-white-15 w-[49%]">
                  {loading ? (
                    <div className="loaderMask">
                      <CircularProgress />
                    </div>
                  ) : (
                    ""
                  )}
                  <div>Konténerek száma: {statsData?.containersNum} db</div>
                  <div>Termék sor összesen: {statsData?.itemsNum} db</div>
                  <div>
                    Mennyi termékre készült ajánlás:{" "}
                    {statsData?.itemsWithRecommendationNum} db
                  </div>
                  <div>
                    Nem tudtunk adni ajánlást:{" "}
                    {statsData?.itemsWithoutRecommendationNum} db
                  </div>
                  <div>
                    Ajánlás alapján döntés: {statsData?.sumSelectsNum} db
                  </div>
                  <div>
                    Ajánlás alapján pozitív döntés:{" "}
                    {statsData?.acceptedRecommendationsNum} db
                  </div>
                  <div>
                    Ajánlás alapján negatív döntés:{" "}
                    {statsData?.rejectedRecommendationsNum} db
                  </div>
                  <div>Elutasított döntések: ?? db</div>
                </div>
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(StatsView);
