import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import BreadCrumb from "../../components/breadcrumb";
import CardBox from "../../components/card-box";
import { Formik, Form } from "formik";
import TextInput from "../../components/text-input";
import Button from "../../components/button";
import Switch from "../../components/Switch";
import withRouter from "../../services/withrouter";
import { getCompanies } from "../companies/store/companiesSlice";
import MenuItem from "@mui/material/MenuItem";
import BaseSelect from "../../components/select/Select"
import { getAccessGroups } from "../access-groups/store/access-group-slice";
import { getLocations } from "../locations/store/locations-slice";
import { createUser } from "./store/usersSlice";
import { generatePassword } from "../../helpers/functions";

const UserCreate = (props) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const companiesData = useSelector((state) => state.companies);
    const accessGroups = useSelector((state) => state.accessGroups);
    const locations = useSelector((state) => state.locations);
    const workspaceId = useSelector((state) => state.auth.workspaceId);

    useEffect(() => {
      console.log("user create useEffect meghívódik!");

      const fetchData = async () => {
        try {
          // Elindítjuk az összes aszinkron műveletet egyidejűleg Promise.all segítségével
          await Promise.all([
            dispatch(getAccessGroups({ workspaceId })),
            dispatch(getCompanies({ workspaceId })),
            dispatch(getLocations({ workspaceId })),
          ]);
  
          setLoading(false)
        } catch (error) {
          console.error('Hiba az API kérések során', error);
        }
      };
  
      fetchData();
      
    }, [dispatch, workspaceId]);

    if (loading) return null

    const initialValues = {
      accessGroupId: "",
      company: "",
      country: "",
      email: "",
      firstName: "",
      lastName: "",
      location: "",
      notes: "",
      phone: "",
    };

    return (
        <>
          {/* BEGIN #content  */}
          <div id="content" className="app-content">
            <BreadCrumb />
            <h1 className="page-header">Create new user</h1>
            <hr className="mb-4" />
    
            <div id="basicTable" className="mb-5">
              <div className="row flex justify-between">
                <div className="w-[49%]">
                  <CardBox title="User data" bodyElement={
                    <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      console.log(values);
                      const password = generatePassword(8);
                      console.log('PASS:', password);
                      dispatch(createUser({ ...values, workspaceId, password }));
                    }}
                    >
                      {(formik) => ( 
                        <Form noValidate autoComplete="off">
                          <div className="relative mb-5">
                            <TextInput disabled variant="standard" label={"ID"} type="text" name="userId" sx={{"& .MuiInputBase-root.Mui-disabled": {
                                color: "#fff"
                            },
                            "& .MuiFormLabel-root.Mui-disabled": {
                                color: "#fff"
                            },}} />
                          </div>
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Firstname"} type="text" name="firstName" />
                          </div>
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Lastname"} name="lastName" type="text" error="" />
                          </div>
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Email"} name="email" type="text" />
                          </div>
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Phone"} name="phone" type="text" />
                          </div>
                          <div className="relative">
                            <BaseSelect
                              fullWidth
                              variant="standard"
                              label="Access level"
                              labelId="access-level-select"
                              size="small"
                              sx={{ mb: 3 }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              onChange={() => console.log('Access level selected')}
                              defaultValue=''
                              name="accessGroupId"
                            >
                              {accessGroups.map((accessGroup, idx) => 
                                <MenuItem key={`ag-${idx}`} value={accessGroup.id}>{accessGroup.groupName}</MenuItem>
                              )}
                            </BaseSelect>
                          </div>
                          <div className="relative">
                            <BaseSelect
                              fullWidth
                              variant="standard"
                              label="Company"
                              labelId="demo-select-small-label"
                              size="small"
                              sx={{ mb: 3 }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              onChange={() => console.log('Company selected')}
                              defaultValue=''
                              name="company"
                            >
                              {companiesData.map((company, idx) => 
                                <MenuItem key={`co-${idx}`} value={company.id}>{company.name}</MenuItem>
                              )}
                            </BaseSelect>
                          </div>
                          <div className="relative">
                            <BaseSelect
                              fullWidth
                              variant="standard"
                              label="Location"
                              labelId="location-select"
                              size="small"
                              sx={{ mb: 3 }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              onChange={() => console.log('Location selected')}
                              defaultValue=''
                              name="location"
                            >
                              {locations.map((location, idx) => 
                                <MenuItem key={`loc-${idx}`} value={location.id}>{location.name}</MenuItem>
                              )}
                            </BaseSelect>
                          </div>
                          <div className="relative mb-6">
                            <BaseSelect
                              fullWidth
                              variant="standard"
                              label="Country"
                              labelId="country-select"
                              size="small"
                              sx={{ mb: 3 }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              defaultValue=''
                              name="country"
                            >
                              <MenuItem key={`country-1`} value={1}>Hungary</MenuItem>
                              <MenuItem key={`country-2`} value={2}>China</MenuItem>
                            </BaseSelect>
                          </div>
                          <div className="relative mb-6">
                            <TextInput
                              id="standard-multiline-static"
                              label="Notes"
                              multiline
                              name="notes"
                              rows={4}
                              defaultValue=""
                              variant="standard"
                              fullWidth
                            />
                          </div>
                          <div className="text-center">
                            <Button size="sm" label="Save" type="button" onClick={() => {
                                console.log('clicked')
                                formik.submitForm()
                              }}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  } />
                </div>
                <div className="w-[49%]">
                  <CardBox title="Company access" bodyElement={
                    <Formik
                    initialValues={
                      {all: true,}
                    }
                    onSubmit={(values) => {
                      console.log(values);
                    }}
                    >
                      {(formik) => (
                        <Form noValidate autoComplete="off">
                          <div className="relative mb-6">
                            <Switch 
                              label="All company access" 
                              name="all" 
                              checked={initialValues.all} 
                              onChange={formik.handleChange} 
                            />
                            {companiesData.map((company, idx) => 
                              <Switch key={`coa-${idx}`} value={company.id} label={company.name} checked={false} onChange={formik.handleChange}  />
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  } />
                </div>
              </div>
                   
            </div>

          </div>
        </>
    );
}

export default withRouter(UserCreate);
