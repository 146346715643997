import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import BreadCrumb from "../../../components/breadcrumb";
import CardBox from "../../../components/card-box";
import { Formik, Form } from "formik";
import TextInput from "../../../components/text-input";
import Button from "../../../components/button";
import withRouter from "../../../services/withrouter";
import { createMaterial } from "./store/materialsSlice";
import MenuItem from "@mui/material/MenuItem";
import BaseSelect from "../../../components/select/Select"
import { InputLabel } from "@mui/material";
import { useNavigate } from "react-router";

const MaterialCreate = (props) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const workspaceId = useSelector((state) => state.auth.workspaceId);

    useEffect(() => {
      console.log("material create useEffect meghívódik!");
      
    }, [dispatch, workspaceId]);

    if (loading) return null

    const initialValues = {
      en: "",
      hu: "",
    };

    const handleSubmitData = (data) => {
      dispatch(
        createMaterial({
          ...data,
          workspaceId: workspaceId,
        })
      )
        .then((res) => {
          navigate("/materials", { replace: true });
        })
        .catch((e) => console.log(e));
    };

    return (
      <>
        {/* BEGIN #content  */}
        <div id="content" className="app-content">
          <BreadCrumb />
          <h1 className="page-header">Create new material</h1>
          <hr className="mb-4" />
  
          <div id="basicTable" className="mb-5">
            <div className="row flex justify-between">
              <div className="w-[49%]">
                <CardBox title="Material data" bodyElement={
                  <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    console.log(values);
                    handleSubmitData(values);
                  }}
                  >
                    {(formik) => ( 
                      <Form noValidate autoComplete="off">

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"English name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="en"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Hungarian name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="hu"
                            />
                          </div>
                        </div>

                        <div className="text-center">
                          <Button size="sm" label="Save" type="button" onClick={() => {
                              console.log('clicked')
                              formik.submitForm()
                            }}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                } />
              </div>

            </div>
                 
          </div>

        </div>
      </>
  );

  }

  export default withRouter(MaterialCreate);