"use client";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { submitLogin } from "./store/loginSlice";
import { Formik, Form } from "formik";
import * as yup from "yup";
import TextInput from "../../components/text-input/TextInput";
import Button from "../../components/button/Button";
import Checkbox from "../../components/checkbox/Checkbox";
import Alert from "@mui/material/Alert";

export default function Login() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [showAlertBox, setShowAlertBox] = useState(null);

  const schema = yup.object().shape({
    loginEmail: yup
      .string()
      .email("login.YOU_MUST_ENTER_VALID_EMAIL")
      .required("login.YOU_MUST_ENTER_EMAIL"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const passClickHandler = async (values) => {
    console.log("ok");
    /*
    dispatch(forgotPassword({ email: values.loginEmail }))
      .then((response) => {
        dispatch(
          showMessage({
            message: `${t("login.FORGOT_PASSWORD_EMAIL_SENT")}`,
            variant: "success",
          })
        );
      })
      .finally(() => {
        setDialog(false);
      });
    */
  };

  const handleLogin = async (values) => {
    dispatch(submitLogin({ email: values.email, password: values.password }, navigation))
      .then((response) => {
        if (response.statusCode === 404) {
          setShowAlertBox(response.message);
        }
      }).catch((error) => {
      })
      .finally(() => {
        // setDialog(false);
      });
  };

  return (
    <div>
      <Formik
        // validationSchema={schema}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          // console.log('formiksubmit values:', values)
          handleLogin(values);
        }}
      >
        {(formik) => (
          <Form noValidate autoComplete="off">
            <section className="h-screen">
              <div className="h-full">
                {/* <!-- Left column container with background--> */}
                <div className="g-6 flex h-full flex-wrap items-center justify-center">

                  {/* <!-- Right column container --> */}
                  <div className="mb-12 md:mb-0 md:w-6/12 lg:w-4/12 xl:w-4/12 xl:max-w-md lg:max-w-md">
                    { showAlertBox ? (<div className="mb-6"><Alert severity="error">{showAlertBox}</Alert></div>) : null }
                      {/* <!--Sign in section--> */}

                      {/* <!-- Email input --> */}
                      <div className="relative mb-6">
                        <TextInput label={"Email"} type="email" name="email" id="email" onChange={formik.handleChange} />
                      </div>

                      {/* <!-- Password input --> */}
                      <div className="relative mb-6" data-te-input-wrapper-init>
                        <TextInput label={"Password"} type="password" name="password" id="password" onChange={formik.handleChange} />
                      </div>

                      <div className="mb-6 flex items-center justify-between">
                        {/* <!-- Remember me checkbox --> */} 
                        <Checkbox id="remember-me" label="Rememberr me" />

                        {/* <!--Forgot password link--> */}
                        <a className="text-neutral-50" href="#!">Forgot password?</a>
                      </div>

                      {/* <!-- Login button --> */}
                      <div className="text-center">
                        <Button label="Sign In" type="button" onClick={() => {
                            formik.submitForm()
                        }}
                        />
                      </div>
                  </div>
                </div>
              </div>
            </section>
          </Form>
        )}
      </Formik>
    </div>
  );
}
