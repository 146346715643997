import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import axios from 'axios'

export const getUsers = createAsyncThunk('users/getUsers', async () => {
	const response = await axios.get(`${process.env.REACT_APP_APIURL}/users`, {
		headers: {
			'Content-Type': 'application/json',
		},
		// withCredentials: true,
	})

	return response;
})

export const getUserById = createAsyncThunk('users/getUserById', async ({ userId }) => {
	const response = await axios.get(`${process.env.REACT_APP_APIURL}/users/${userId}`, {
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return response;
})

export const createUser = createAsyncThunk('users/createUser', async (payload) => {
	const response = await axios.post(`${process.env.REACT_APP_APIURL}/users`, payload, {
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return response;
})

const containersAdapter = createEntityAdapter({})

export const { selectAll: selectUsers, selectById: selectProjectById } = containersAdapter.getSelectors(
	state => state.users
)

const containersSlice = createSlice({
	name: 'users',
	initialState: [],
	reducers: {},
	extraReducers: {
		// [getUsers.fulfilled]: containersAdapter.setAll,
		[getUsers.fulfilled]: (state, action) => action.payload.data,
		// [getUsers.fulfilled]: (state, action) => containersAdapter.setAll(action.payload.data),
		// [removeUsers.fulfilled]: (state, action) => containersAdapter.removeMany(state, action.payload),
	},
})

export const { setUsersSearchText } = containersSlice.actions

export default containersSlice.reducer
