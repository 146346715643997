import { useState } from "react";

export default function Dashboard() {
  // eslint-disable-next-line no-unused-vars
  const [admin, setAdmin] = useState(null);

  return (
    <>
      {/* BEGIN #content */}
      <div id="content" className="app-content w-full">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <button>Dashboard</button>
          </li>
        </ul>
        <h1 className="page-header">
          Dashboard
          <button
            type="button"
            className="btn btn-sm btn-outline-theme ms-3"
            data-bs-toggle="modal"
            data-bs-target="#dashboard_filter_modal"
          >
            <i className="fas me-1 fa-filter opacity-5"></i> Filter
          </button>
        </h1>
        <hr className="mb-4" />

        <div className="row row flex justify-between">
          <div className="col-md-4 w-1/3">
            <div className="mb-5">
              <div className="card" id="weeks">
                echo $weeks;
              </div>
            </div>
          </div>
          <div className="col-md-4 w-1/3">
            <div className="mb-5">
              <div className="card">
                <div className="card-header d-flex align-items-center bg-white bg-opacity-15">
                  <span className="flex-grow-1 fw-400">Container flow</span>
                </div>
                <div id="flow" className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-1 fs-11px me-2"></span>
                      <b>01 New</b>
                    </div>
                    <button>
                      state30;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-2 fs-11px me-2"></span>
                      <b>02 Waiting for initial docs</b>
                    </div>
                    <button>
                      state31;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-3 fs-11px me-2"></span>
                      <b>02B Waiting for revised docs I.</b>
                    </div>
                    <button>
                      state32;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-3 fs-11px me-2"></span>
                      <b>02C Waiting for revised docs II.</b>
                    </div>
                    <button>
                      state84;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-4 fs-11px me-2"></span>
                      <b>03 Waiting for haulier/truck allocation</b>
                    </div>
                    <button>
                      state36;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-5 fs-11px me-2"></span>
                      <b>04 Waiting for arrival & docs</b>
                    </div>
                    <button>
                      state37;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-6 fs-11px me-2"></span>
                      <b>05 Waiting for pickup</b>
                    </div>
                    <button>
                      state38;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-7 fs-11px me-2"></span>
                      <b>06 Waiting for arrival to CC (OTR)</b>
                    </div>
                    <button>
                      state39;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-8 fs-11px me-2"></span>
                      <b>07 Waiting for CC</b>
                    </div>
                    <button>
                      state40;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-9 fs-11px me-2"></span>
                      <b>08 Waiting for arrival to destination (OTR)</b>
                    </div>
                    <button>
                      state41;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-10 fs-11px me-2"></span>
                      <b>09 Waiting for offloading</b>
                    </div>
                    <button>
                      state42;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-11 fs-11px me-2"></span>
                      <b>10 Waiting for empty return</b>
                    </div>
                    <button>
                      state43;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-12 fs-11px me-2"></span>
                      <b>11 Waiting for return documents</b>
                    </div>
                    <button>
                      state44;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-13 fs-11px me-2"></span>
                      <b>12 Job completed :)</b>
                    </div>
                    <button>
                      state45;{" "}
                    </button>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <span className="fa fa-circle text-staus-14 fs-11px me-2"></span>
                      <b>13 Cancelled :(</b>
                    </div>
                    <button>
                      state78;{" "}
                    </button>
                  </div>
                </div>
                <div className="card-arrow">
                  <div className="card-arrow-top-left"></div>
                  <div className="card-arrow-top-right"></div>
                  <div className="card-arrow-bottom-left"></div>
                  <div className="card-arrow-bottom-right"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 w-1/3">
            <div className="mb-5">
              <div className="card notifications-card">
                <div className="card-header d-flex align-items-center bg-white bg-opacity-15">
                  <span className="flex-grow-1 fw-400">Tasks & notifications</span>
                </div>
                <div className="list-group list-group-flush">
                  notifications;
                  <div className="list-group-item px-3 text-center">
                    <button type="button" className="btn btn-sm btn-outline-theme">
                      <i className="fas opacity-5 fa-fw fa-archive"></i> See
                      archives
                    </button>
                  </div>
                </div>
                <div className="card-arrow">
                  <div className="card-arrow-top-left"></div>
                  <div className="card-arrow-top-right"></div>
                  <div className="card-arrow-bottom-left"></div>
                  <div className="card-arrow-bottom-right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* END #content */}

      <div className="modal fade" id="dashboard_filter_modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Filter</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              {admin ? (
                <div className="card notifications-card">
                  <div className="card-header d-flex align-items-center bg-white bg-opacity-15">
                    <a
                      className="flex-grow-1 fw-400"
                      data-bs-toggle="collapse"
                      href="#filter_group_2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      Company groups
                    </a>
                  </div>
                  <div className="collapse" id="filter_group_2">
                    <div className="card-body">cgroup;</div>
                  </div>
                  <div className="card-arrow">
                    <div className="card-arrow-top-left"></div>
                    <div className="card-arrow-top-right"></div>
                    <div className="card-arrow-bottom-left"></div>
                    <div className="card-arrow-bottom-right"></div>
                  </div>
                </div>
              ) : null}
              <div className="card notifications-card">
                <div className="card-header d-flex align-items-center bg-white bg-opacity-15">
                  <a
                    className="flex-grow-1 fw-400"
                    data-bs-toggle="collapse"
                    href="#filter_group_3"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Arrival locations
                  </a>
                </div>
                <div className="collapse" id="filter_group_3">
                  <div className="card-body">aloc;</div>
                </div>
                <div className="card-arrow">
                  <div className="card-arrow-top-left"></div>
                  <div className="card-arrow-top-right"></div>
                  <div className="card-arrow-bottom-left"></div>
                  <div className="card-arrow-bottom-right"></div>
                </div>
              </div>
              <div className="card notifications-card">
                <div className="card-header d-flex align-items-center bg-white bg-opacity-15">
                  <a
                    className="flex-grow-1 fw-400"
                    data-bs-toggle="collapse"
                    href="#filter_group_4"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Custom clearence locations
                  </a>
                </div>
                <div className="collapse" id="filter_group_4">
                  <div className="card-body">ccloc;</div>
                </div>
                <div className="card-arrow">
                  <div className="card-arrow-top-left"></div>
                  <div className="card-arrow-top-right"></div>
                  <div className="card-arrow-bottom-left"></div>
                  <div className="card-arrow-bottom-right"></div>
                </div>
              </div>
              <div className="card notifications-card">
                <div className="card-header d-flex align-items-center bg-white bg-opacity-15">
                  <a
                    className="flex-grow-1 fw-400"
                    data-bs-toggle="collapse"
                    href="#filter_group_5"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Final delivery locations
                  </a>
                </div>
                <div className="collapse" id="filter_group_5">
                  <div className="card-body">fdloc;</div>
                </div>
                <div className="card-arrow">
                  <div className="card-arrow-top-left"></div>
                  <div className="card-arrow-top-right"></div>
                  <div className="card-arrow-bottom-left"></div>
                  <div className="card-arrow-bottom-right"></div>
                </div>
              </div>
              <button
                onClick={() => console.log('ok')}
                className="btn btn-outline-theme mt-3"
                data-bs-dismiss="modal"
              >
                <i className="fas opacity-5 fa-fw fa-search"></i> Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
