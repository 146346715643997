// import TextInput from './TextInput'
// import TextField from '@mui/material/TextField'
import { withFormikField } from "../../shared-components";
import InputLabel from "@mui/material/InputLabel";
import { OutlinedInput } from "@mui/material";
/*
const Field = (props) => {
  return (
    <div className="flex">
      <div className="w-5/12 self-center">
        <InputLabel>{props.label}</InputLabel>
      </div>
      <div className="w-7/12">
        <OutlinedInput
          id="outlined-adornment-weight"
          fullWidth
          {...props}
          label={null}
          inputProps={{
            "aria-label": "weight",
          }}
        />
      </div>
    </div>
  );
};
*/

export default withFormikField(OutlinedInput);
// export { default } from './TextInput'
