import { Link } from "react-router-dom";
import Pagination from "./pagination";

export default function DataTable({ columns, data }) {
  const generateHeaders = function (columns) {
    const results = [];
    let i = 0;

    for (const column of columns) {
      results.push(
        <th scope="col" className="px-6 py-2" key={`col-${i}`}>
          {column.columnTitle}
        </th>
      );
      i++;
    }

    return results;
  };

  const generateData = function (columns, data) {
    const results = [];
    let i = 0;

    for (const row of data) {
      const str = (
        <tr
          key={`row-${i}`}
          className="border-b-white/30 border-b transition duration-300 ease-in-out hover:bg-neutral-100 hover:bg-opacity-10"
        >
          {columns.map((column, j) => {
            return (
              <td key={`field-${j}`} className="whitespace-nowrap px-6 py-2">
                {typeof row[column.dataKey[0]] === "object" &&
                row[column.dataKey[0]] !== null ? (
                  row[column.dataKey[0]]?.link ? (
                    <Link to={row[column.dataKey[0]].link}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${row[column.dataKey[0]].column}`,
                        }}
                      ></div>
                    </Link>
                  ) : row[column.dataKey[0]]?.html ? (
                    (console.log("html-re fut"),
                    (
                      <div>{row[column.dataKey[0]].html}</div>
                    ))
                  ) : (
                    row[column.dataKey[0]].column
                  )
                ) : (
                  row[column.dataKey[0]]
                )}
              </td>
            );
          })}
        </tr>
      );
      results.push(str);

      i++;
    }

    return results;
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light">
              <thead className="border-b border-neutral-500 font-medium dark:border-neutral-500">
                <tr>{generateHeaders(columns)}</tr>
              </thead>
              <tbody>{generateData(columns, data)}</tbody>
            </table>
            <Pagination />
          </div>
        </div>
      </div>
    </div>
  );
}
