import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import routes from "../../routes";

export default function BreadCrumb(props) {
  const [myRoutes, setMyRoutes] = useState({});
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const obj = {};
    for (const route of routes) {
      delete route.element;
      obj[route.path] = route;
      if (route.children?.length) {
        for (const childRoute of route.children) {
          delete childRoute.element;
          delete childRoute.index;
          if (childRoute.path === "") {
            obj[route.path] = childRoute;
            obj[route.path].path = route.path;
          } else {
            obj[childRoute.path] = childRoute;
          }
        }
      }
    }

    setMyRoutes(obj);
    setLoading(false);
  }, [location.path]);

  if (loading || !location) return null;

  return (
    <ul className="breadcrumb">
      {location.pathname
        ? location.pathname.split("/").map((item, idx, array) => {
            let currpath = array.slice(0, idx + 1).join("/");

            if (!currpath) {
              return (
                <li key={`crumb-${idx}`} className="breadcrumb-item active">
                  <Link to="/">Dashboard</Link>
                </li>
              );
            }

            return (
              <li key={`crumb-${idx}`} className="breadcrumb-item active">
                {idx === array.length - 1 ? (
                  myRoutes[currpath]?.title
                ) : (
                  <Link to={myRoutes?.[currpath]?.path}>
                    {myRoutes?.[currpath]?.title}
                  </Link>           
                )}
              </li>
            );
          })
        : null}
    </ul>
  );
}
