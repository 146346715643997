import DialogTitle from "@mui/material/DialogTitle";
import Dialog from '@mui/material/Dialog';

export default function MuiDialog (props) {
    const { open, handleClose } = props;

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{props.title}</DialogTitle>
        </Dialog>
    );
}