import { TEInput } from "tw-elements-react";

export default function TextInput(props) {

    return (
        <TEInput 
            {...props}
            className="text-white" 
            theme={
                {
                input:
                "peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear  peer-focus:text-primary motion-reduce:transition-none placeholder:opacity-0 disabled:bg-neutral-100 read-only:bg-neutral-100 focus:outline-none active:outline-none",
                activeInput: "placeholder:opacity-100 outline-none",
                label:
                "pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate text-white transition-all duration-200 ease-out peer-focus:scale-[0.8] peer-focus:text-primary motion-reduce:transition-none",
                activeLabel: "scale-[0.8]",
                notch:
                "group flex absolute left-0 top-0 w-full max-w-full h-full text-left pointer-events-none",
                notchLeading:
                "pointer-events-none  bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none left-0 top-0 h-full w-2 border-r-0 rounded-l-[0.25rem]",
                activeNotchLeading: "border-r-0",
                focusedNotchLeading: "border-r-0 ",
                notchLeadingDefault: "border-neutral-300",
                focusedNotchLeadingDefault:
                "border-primary",

                notchMiddle:
                "outline-none pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow-0 shrink-0 basis-auto w-auto max-w-[calc(100%-1rem)] h-full border-r-0 border-l-0 border-t-0",
                activeNotchMiddle:
                "border-x-0 border-t border-solid border-t-transparent dark:border-t-transparent",
                focusedNotchMiddle:
                "border-x-0 border-t border-solid border-t-transparent dark:border-t-transparent",
                notchMiddleDefault: "border-neutral-300",
                focusedNotchMiddleDefault:
                "border-primary",

                notchTrailing:
                "pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow h-full border-l-0 border-t-0 border-r-0 rounded-r-[0rem]",
                activeNotchTrailing: "border-l-0",
                focusedNotchTrailing: "border-l-0",
                notchTrailingDefault: "border-neutral-300 dark:border-neutral-600",
                focusedNotchTrailingDefault:
                "border-primary",
                }
            }
        />
    )
}