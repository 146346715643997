export default function Pagination() {
  return (
    <>
      {/* <!-- Second navigation component with smaller text and padding --> */}
      <nav aria-label="Page navigation example">
        {/* <!-- Unordered list for the navigation items --> */}
        <ul className="list-style-none flex">
          {/* <!-- Previous page item with disabled pointer events --> */}
          <li>
            <a className="pointer-events-none relative block rounded bg-transparent px-2 py-1 text-sm text-neutral-500 transition-all duration-300 dark:text-neutral-400">
              Previous
            </a>
          </li>
          {/* <!-- Page number 1 item --> */}
          <li>
            <a
              className="relative block rounded bg-transparent px-2 py-1 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100  dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"
              href="#!"
            >
              1
            </a>
          </li>
          {/* <!-- Current page (2) item with highlighted background and "current" label --> */}
          <li aria-current="page">
            <a
              className="relative block rounded bg-primary-100 px-2 py-1 text-sm font-medium text-primary-700 transition-all duration-300"
              href="#!"
            >
              2{/* <!-- Hidden "current" label for screen readers --> */}
              <span className="absolute -m-px h-px w-px overflow-hidden whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]">
                (current)
              </span>
            </a>
          </li>
          {/* <!-- Page number 3 item --> */}
          <li>
            <a
              className="relative block rounded bg-transparent px-2 py-1 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"
              href="#!"
            >
              3
            </a>
          </li>
          {/* <!-- Next page item --> */}
          <li>
            <a
              className="relative block rounded bg-transparent px-2 py-1 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"
              href="#!"
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}
