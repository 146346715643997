import Dashboard from "../pages/dashboard";
import ContainersList from "../pages/containers/list";
import Locations from "../pages/locations";
import Supports from "../pages/support-tables/main-support-table";
import SupportCreate from "../pages/support-tables/main-support-table/SupportCreate";
import SupportEdit from "../pages/support-tables/main-support-table/SupportEdit";
import Materials from "../pages/support-tables/materials";
import MaterialCreate from "../pages/support-tables/materials/MaterialCreate";
import MaterialEdit from "../pages/support-tables/materials/MaterialEdit";
import Users from "../pages/users";
import Login from "../pages/login";
import UserEdit from "../pages/users/edit";
import UserCreate from "../pages/users/create";
import { CompanyCreate, CompanyEdit, CompanyList } from "../pages/companies";
import { MasterDbEditorList } from "../pages/ai/master-db-editor";
import { StatsView } from "../pages/ai-stats";
import { logout } from "../services/jwtservice";

const routes = [
  {
    path: "/",
    title: "Dashboard",
    role: ["user", "admin"],
    layout: true,
    component: <Dashboard />,
  },
  {
    path: "/users",
    title: "Users",
    role: ["user", "admin"],
    layout: true,
    component: <Users />,
  },
  {
    path: "/users/:userId",
    title: "Edit",
    role: ["user", "admin"],
    layout: true,
    component: <UserEdit />,
  },
  {
    path: "/users/new",
    title: "Create user",
    role: ["user", "admin"],
    layout: true,
    component: <UserCreate />,
  },
  {
    path: "/companies",
    title: "Companies",
    role: ["user", "admin"],
    layout: true,
    component: <CompanyList />,
  },
  {
    path: "/companies/:companyId",
    title: "Edit",
    role: ["user", "admin"],
    layout: true,
    component: <CompanyEdit />,
  },
  {
    path: "/companies/new",
    title: "Create company",
    role: ["user", "admin"],
    layout: true,
    component: <CompanyCreate />,
  },
  {
    path: "/containers",
    title: "Containers",
    role: ["user", "admin"],
    layout: true,
    component: <ContainersList />,
  },
  {
    path: "/locations",
    title: "Locations",
    role: ["user", "admin"],
    layout: true,
    component: <Locations />,
  },
  {
    path: "/supports",
    title: "Supports",
    role: ["user", "admin"],
    layout: true,
    component: <Supports />,
  },
  {
    path: "/supports/new/:supportId?",
    title: "New",
    role: ["user", "admin"],
    layout: true,
    component: <SupportCreate />,
  },
  {
    path: "/supports/:supportId",
    title: "Edit",
    role: ["user", "admin"],
    layout: true,
    component: <SupportEdit />,
  },  
  {
    path: "/materials",
    title: "Materials",
    role: ["user", "admin"],
    layout: true,
    component: <Materials />,
  },
  {
    path: "/materials/new",
    title: "New",
    role: ["user", "admin"],
    layout: true,
    component: <MaterialCreate />,
  },
  {
    path: "/materials/:materialId",
    title: "Edit",
    role: ["user", "admin"],
    layout: true,
    component: <MaterialEdit />,
  },
  {
    path: "/masterdb-editor",
    title: "Master DB Editor",
    role: ["user", "admin"],
    layout: true,
    component: <MasterDbEditorList />,
  },
  {
    path: "/ai-stats",
    title: "AI Stats",
    role: ["user", "admin"],
    layout: true,
    component: <StatsView />,
  },
  {
    index: true,
    path: "/login",
    title: "Login",
    layout: false,
    role: [],
    component: <Login />,
  },
  {
    path: "/logout",
    title: "Logout",
    layout: false,
    role: [],
    component: ({ navigate }) => {
      console.log("LOGOUT!");
      logout(navigate);
    },
  },
];

export default routes;
