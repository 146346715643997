import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { signInWithEmailAndPassword } from "../../../services/jwtservice";

export const submitLogin = ({ email, password }, navigation) => async (dispatch) => {

    return signInWithEmailAndPassword(email, password)
            .then((response) => {
            //    dispatch(getOneWayNotification());
            //    dispatch(
            //    storeBrowserInfo({
            //        browser: `${browserName} ${fullBrowserVersion}`,
            //        os: `${osName} ${osVersion}`,
            //        resolution: `${window.screen.width}x${window.screen.height}`,
            //    })
            //    );
              navigation('/');
            })
            .catch((errors) => {
                return errors;
            //    return dispatch(
            //    showMessage({
            //        message: `${errors?.data?.errors[0]} (${errors.status})`,
            //        variant: "error",
            //    })
            //    );
            });
  };

export const signInWithEmailAndPasswords = createAsyncThunk('auth/login', async (email, password) => {
  const response = await axios.post(`${process.env.REACT_APP_APIURL}/auth/login`, {
    email: email,
    password: password,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response;
})

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    workspaceId: 1,
  },
  reducers: {},
  extraReducers: {
    // [getUsers.fulfilled]: authAdapter.setAll,
    [signInWithEmailAndPasswords.fulfilled]: (state, action) => action.payload.data,
    // [getUsers.fulfilled]: (state, action) => authAdapter.setAll(action.payload.data),
    // [removeUsers.fulfilled]: (state, action) => authAdapter.removeMany(state, action.payload),
  },
})

export default authSlice.reducer