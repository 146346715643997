import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getMaterials = createAsyncThunk(
  "materials/getMaterial",
  async ({ workspaceId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/materials/list/${workspaceId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response;
  }
);

export const getMaterialById = createAsyncThunk(
  "materials/getMaterialById",
  async ({ materialId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/materials/${materialId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response;
  }
);

export const editMaterial = createAsyncThunk(
  "materials/updateMaterialById",
  async (payload) => {
    console.log("payload: ", payload);
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/materials/${payload.id}`,
      {
        en: payload.en,
        hu: payload.hu,
        workspaceId: payload.workspaceId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response;
  }
);

export const deleteMaterial = createAsyncThunk(
  "materials/deleteMaterial",
  async ({ id }) => {
    console.log("payload: ", id);
    const response = await axios.delete(
      `${process.env.REACT_APP_APIURL}/materials/${id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response;
  }
);

export const createMaterial = createAsyncThunk(
  "materials/createMaterial",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/materials`,
      {
        en: payload.en,
        hu: payload.hu,
        workspaceId: payload.workspaceId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response;
  }
);

const containersAdapter = createEntityAdapter({});

export const { selectAll: selectMaterials, selectById: selectProjectById } =
  containersAdapter.getSelectors((state) => state.materials);

const materialsSlice = createSlice({
  name: "materials",
  initialState: [],
  reducers: {},
  extraReducers: {
    // [getUsers.fulfilled]: containersAdapter.setAll,
    [getMaterials.fulfilled]: (state, action) => action.payload.data,
    [deleteMaterial.fulfilled]: (state, action) => {
      console.log('action payload on delete material:', action);
      return state.filter((item) => item.id !== action.meta.arg.id)
    },
    [deleteMaterial.rejected]: (state, action) => {
      console.log('action payload on delete material errorra futott:', action);
    }
    // [getUsers.fulfilled]: (state, action) => containersAdapter.setAll(action.payload.data),
    // [removeUsers.fulfilled]: (state, action) => containersAdapter.removeMany(state, action.payload),
  },
});

export const { setMaterialsSearchText } = materialsSlice.actions;

export default materialsSlice.reducer;
