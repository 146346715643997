import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import reducer from "./store";

import withReducer from "../../store/withReducer";

function ContainersList() {
  const dispatch = useDispatch();
  const [insert, setInsert] = useState(0);
  const [containerExport, setContainerExport] = useState(0);

  console.log('container meghívódik!')
  useEffect(() => {
    console.log('container useEffect meghívódik!')
  }, [])

  return (
    <>
      {/* BEGIN #content */}
      <div id="content" className="app-content">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Dashboard</a>
          </li>
          <li className="breadcrumb-item active">Containers | List</li>
        </ul>
        <h1 className="page-header">
          Containers
          <a
            href="/containers/kanban"
            className="btn btn-sm btn-outline-theme ms-3"
          >
            <i className="bi bi-kanban me-1 opacity-5"></i> Kanban view
          </a>
        </h1>

        <div id="basicTable">
          <div className="card">
            <div className="card-body">
              <div className="border-bottom">
                <div className="row align-items-center">
                  <div className="col-md-12 text-end">
                    <div className="row justify-content-end mb-2">
                      <div className="col-auto">
                        <select
                          name="filters"
                          id="filters"
                          className="form-select"
                        >
                          <option value="">Quickfilter</option>
                          $filters
                        </select>
                      </div>
                      <div className="col-auto">
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          data-bs-toggle="modal"
                          data-bs-target="#modal_settings"
                        >
                          <i className="fa-solid fa-gear"></i> View settings
                        </button>
                      </div>
                      {insert === 1 ? (
                        <div className="col-auto">
                          <a
                            href="/containers/new"
                            className="btn btn-outline-theme"
                          >
                            <i className="fa-solid fa-cube"></i> New container
                          </a>
                        </div>
                      ) : null}
                      {containerExport === 1 ? (
                        <div className="col-auto">
                          <a
                            href="/containers/export"
                            className="btn btn-outline-theme"
                          >
                            <i className="fa-solid fa-download"></i> Export
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-list-table">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr></tr>
                    </thead>
                    <tbody id="scrollcontainers">
                      <tr>
                        <td>$containers</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card-arrow">
              <div className="card-arrow-top-left"></div>
              <div className="card-arrow-top-right"></div>
              <div className="card-arrow-bottom-left"></div>
              <div className="card-arrow-bottom-right"></div>
            </div>
          </div>
        </div>
      </div>
      {/* END #content */}

      <div className="modal fade" id="modal_settings">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">View settings</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title mb-3">
                Visible fields
                <span className="float-end">
                  <button
                    type="button"
                    className="btn btn-outline-theme btn-sm"
                  >
                    Check all
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-theme btn-sm"
                  >
                    Clear all
                  </button>
                </span>
              </h5>
              <div className="col-md-12">
                <div className="dumpling-box" id="viewedfields">
                  $setfields;
                </div>
              </div>
              <h5 className="modal-title">Order</h5>
              <div className="row mb-n3">
                <div className="col-xl-6">
                  <select
                    className="form-select form-select-lg mb-3"
                    id="orderfields"
                  >
                    $setorder;
                  </select>
                </div>
                <div className="col-xl-6">
                  <select
                    className="form-select form-select-lg mb-3"
                    id="orderdir"
                  >
                    $setdir;
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-outline-theme">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal_filtername">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Save quickfilter</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title">Filtername:</h5>
              <div className="row mb-n3">
                <div className="col-xl-8">
                  <input
                    type="text"
                    className="form-control"
                    id="filtername"
                    name="filtername"
                    maxLength="30"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-outline-theme">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal_deletefilter">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete quickfilter</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                Are you sure you want to delete?
                <div id="dfiltername"></div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-outline-theme">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withReducer("eCommerceApp", reducer)(ContainersList);
