export const companyType = [
    { id: 1, name: 'AEO' },
    { id: 2, name: 'EU buyer' },
    { id: 3, name: 'EU Importing' },
    { id: 4, name: 'Exporting' },
    { id: 5, name: 'Forwarding' },
    { id: 6, name: 'Haulier' },
    { id: 7, name: 'Trade agent' },
];

export const cities = [
    { id: 1, name: 'Budapest' },
    { id: 2, name: 'Sanghai' },
];