import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import BreadCrumb from "../../components/breadcrumb";
import CardBox from "../../components/card-box";
import { Formik, Form } from "formik";
import TextInput from "../../components/text-input";
import Button from "../../components/button";
import withRouter from "../../services/withrouter";
import { createCompany } from "./store/companiesSlice";
import MenuItem from "@mui/material/MenuItem";
import BaseSelect from "../../components/select/Select"

const CompanyCreate = (props) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const workspaceId = useSelector((state) => state.auth.workspaceId);

    useEffect(() => {
      console.log("company create useEffect meghívódik!");
      
    }, [dispatch, workspaceId]);

    if (loading) return null

    const companyTypes = [
      {id: 1, name: 'AEO'},
      {id: 2, name: 'EU buyer'},
      {id: 3, name: 'EU Importing'},
      {id: 4, name: 'Exporting'},
      {id: 5, name: 'Forwarding'},
      {id: 6, name: 'Haulier'},
      {id: 7, name: 'Trade agent'},
    ]

    const initialValues = {
      companyType: "",
      companyName: "",
      centralEmail: "",
      centralPhone: "",
      website: "",
      country: "",
      city: "",
      zipCode: "",
      street: "",
      houseNumber: "",
      openingHours: "",
      euVat: "",
      regNumber: "",
      bankAccount: "",
      notes: "",
      companyDirector: "",
    };

    return (
        <>
          {/* BEGIN #content  */}
          <div id="content" className="app-content">
            <BreadCrumb />
            <h1 className="page-header">Create new company</h1>
            <hr className="mb-4" />
    
            <div id="basicTable" className="mb-5">
              <div className="row flex justify-between">
                <div className="w-[49%]">
                  <CardBox title="Company data" bodyElement={
                    <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      console.log(values);
                      dispatch(createCompany({ ...values, workspaceId }));
                    }}
                    >
                      {(formik) => ( 
                        <Form noValidate autoComplete="off">
                          <div className="relative">
                            <BaseSelect
                              fullWidth
                              variant="standard"
                              label="Company type"
                              labelId="access-level-select"
                              size="small"
                              sx={{ mb: 3 }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              onChange={() => console.log('Company type selected')}
                              defaultValue=''
                              name="companyType"
                            >
                              {companyTypes.map((companyType, idx) => 
                                <MenuItem key={`ag-${idx}`} value={companyType.id}>{companyType.name}</MenuItem>
                              )}
                            </BaseSelect>
                          </div>
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Company name"} type="text" name="companyName" />
                          </div>
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Central email"} name="centralEmail" type="text" error="" />
                          </div>
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Central phone"} name="centralPhone" type="text" />
                          </div>
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Website"} name="website" type="text" />
                          </div>

                          <div className="relative">
                            <BaseSelect
                              fullWidth
                              variant="standard"
                              label="Country"
                              labelId="country-select"
                              size="small"
                              sx={{ mb: 3 }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              defaultValue=''
                              name="country"
                            >
                              <MenuItem key={`country-1`} value={1}>Hungary</MenuItem>
                              <MenuItem key={`country-2`} value={2}>China</MenuItem>
                            </BaseSelect>
                          </div>

                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"City"} name="city" type="text" />
                          </div>
                          
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"ZIP Code"} name="zipCode" type="text" />
                          </div>
                          
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Street"} name="street" type="text" />
                          </div>
                          
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"House number"} name="houseNumber" type="text" />
                          </div>

                          <div className="relative mb-6">
                            <TextInput
                              id="standard-multiline-static"
                              label="Opening hours"
                              multiline
                              name="openingHours"
                              rows={4}
                              defaultValue=""
                              variant="standard"
                              fullWidth
                            />
                          </div>

                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"EU VAT"} name="euVat" type="text" />
                          </div>
                          
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Registration number"} name="regNumber" type="text" />
                          </div>
                          
                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Bank account"} name="bankAccount" type="text" />
                          </div>

                          <div className="relative mb-6">
                            <TextInput
                              id="standard-multiline-static"
                              label="Notes"
                              multiline
                              name="notes"
                              rows={4}
                              defaultValue=""
                              variant="standard"
                              fullWidth
                            />
                          </div>

                          <div className="relative mb-5">
                            <TextInput fullWidth variant="standard" label={"Company director"} name="companyDirector" type="text" />
                          </div>
                          <div className="text-center">
                            <Button size="sm" label="Save" type="button" onClick={() => {
                                console.log('clicked')
                                formik.submitForm()
                              }}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  } />
                </div>

              </div>
                   
            </div>

          </div>
        </>
    );
}

export default withRouter(CompanyCreate);
