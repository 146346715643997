import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { deleteMaterial, getMaterials } from "./store/materialsSlice";
import { Link } from "react-router-dom";
import DataTable from "../../../components/datatable";
import BreadCrumb from "../../../components/breadcrumb";
import { Button } from "@mui/material";

export default function Materials() {
  const dispatch = useDispatch();
  const materialsData = useSelector((state) => state.materials);
  const workspaceId = useSelector((state) => state.auth.workspaceId);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("materials list useEffect meghívódik!");
    dispatch(getMaterials({ workspaceId: workspaceId })).then(
      (res) => console.log("material resp:", res),
      setLoading(false)
    );
    // setLoading(false); <-- ez már nem kell OK, mert itt van felette igen látom
  }, [dispatch]);

  const handleDelete = ({ id }) => {
    dispatch(deleteMaterial({ id: id }))
      .then(() => console.log("sikeresen törölve"))
      .catch((e) => console.log(`hiba történt a delete során:`, e));
  };

  if (loading) return <>Loading...</>;

  const materialsTransformedData = materialsData.map((item) => ({
    ...item,
    id: {
      link: `/materials/${item.id}`,
      column: item.id,
    },
    en: {
      link: `/materials/${item.id}`,
      column: item.en,
    },
    hu: {
      link: `/materials/${item.id}`,
      column: item.hu,
    },
    edit: {
      html: (
        <Button onClick={() => handleDelete({ id: item.id })}>Delete</Button>
      ),
    },
    createdAt: dayjs(item.createdAt).format("YYYY-MM-DD"),
  }));

  const tableColumns = [
    {
      columnTitle: "ID",
      dataKey: ["id"],
      canOrder: true,
    },
    {
      columnTitle: "EN name",
      dataKey: ["en"],
      canOrder: true,
    },
    {
      columnTitle: "HU name",
      dataKey: ["hu"],
      canOrder: true,
    },
    {
      columnTitle: "DEL",
      dataKey: ["edit"],
      canOrder: false,
    },
  ];

  return (
    <>
      {/* BEGIN #content  */}
      <div id="content" className="app-content">
        <BreadCrumb />

        <h1 className="page-header">Materials</h1>
        <hr className="mb-4" />

        <div id="basicTable" className="mb-5">
          <div className="card">
            <div className="card-body">
              <div className="border-bottom">
                <div className="row align-items-center">
                  <div className="col-md-12 text-end">
                    <div className="row justify-content-end mb-2">
                      <div className="col-auto">
                        <select
                          name="filters"
                          id="filters"
                          className="form-select"
                        >
                          <option value="">Quickfilter</option>
                          $filters;
                        </select>
                      </div>
                      <div className="col-auto">
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          data-bs-toggle="modal"
                          data-bs-target="#modal_settings"
                        >
                          <i className="fa-solid fa-gear"></i> View settings
                        </button>
                      </div>
                      <div className="col-auto">
                        <Link
                          to="/materials/new"
                          className="btn btn-outline-theme"
                        >
                          <i className="fa-solid fa-user"></i> New data
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <DataTable
                  columns={tableColumns}
                  data={materialsTransformedData}
                />
              </div>
            </div>

            <div className="card-arrow">
              <div className="card-arrow-top-left"></div>
              <div className="card-arrow-top-right"></div>
              <div className="card-arrow-bottom-left"></div>
              <div className="card-arrow-bottom-right"></div>
            </div>
          </div>
        </div>
      </div>
      {/* END #content */}
    </>
  );
}

export { default as MaterialCreate } from "./MaterialCreate";
