import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getSupports = createAsyncThunk(
  "supports/getSupport",
  async ({ workspaceId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/supports/list/${workspaceId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response;
  }
);

export const getSupportById = createAsyncThunk(
  "supports/getSupportById",
  async ({ supportId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/supports/${supportId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response;
  }
);

export const editSupport = createAsyncThunk(
  "supports/updateSupportById",
  async (payload) => {
    console.log("payload: ", payload);
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/supports/${payload.id}`,
      {
        tenDigit: payload.tenDigit || "",
        tenDigitName: payload.tenDigitName || "",
        descEn: payload.descEn || "",
        descHu: payload.descHu || "",
        materialEn: payload.materialEn || "",
        materialHu: payload.materialHu || "",
        duty: payload.duty || "",
        dutyTaric: payload.dutyTaric || "",
        olafPrice: payload.olafPrice || "",
        category: payload.category || "",
        sectionNum: payload.sectionNum || "",
        sectionName: payload.sectionName || "",
        chapterNum: payload.chapterNum || "",
        chapterName: payload.chapterName || "",
        subChapterName: payload.subChapterName || "",
        fourDigit: payload.fourDigit || "",
        fourDigitName: payload.fourDigitName || "",
        sixDigit: payload.sixDigit || "",
        sixDigitName: payload.sixDigitName || "",
        eightDigit: payload.eightDigit || "",
        eightDigitName: payload.eightDigitName || "",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response;
  }
);

export const copySupport = createAsyncThunk(
  "supports/copySupport",
  async ({ id }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/supports/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response;
  }
);

export const deleteSupport = createAsyncThunk(
  "supports/deleteSupport",
  async ({ id }) => {
    console.log("payload: ", id);
    const response = await axios.delete(
      `${process.env.REACT_APP_APIURL}/supports/${id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response;
  }
);

export const createSupport = createAsyncThunk(
  "supports/createSupport",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/supports`,
      {
        tenDigit: payload.tenDigit || "",
        tenDigitName: payload.tenDigitName || "",
        descEn: payload.descEn || "",
        descHu: payload.descHu || "",
        materialEn: payload.materialEn || "",
        materialHu: payload.materialHu || "",
        duty: payload.duty,
        dutyTaric: payload.dutyTaric || "",
        olafPrice: payload.olafPrice || "",
        category: payload.category || "",
        sectionNum: payload.sectionNum || "",
        sectionName: payload.sectionName || "",
        chapterNum: payload.chapterNum || "",
        chapterName: payload.chapterName || "",
        subChapterName: payload.subChapterName || "",
        fourDigit: payload.fourDigit || "",
        fourDigitName: payload.fourDigitName || "",
        sixDigit: payload.sixDigit || "",
        sixDigitName: payload.sixDigitName || "",
        eightDigit: payload.eightDigit || "",
        eightDigitName: payload.eightDigitName || "",        

        workspaceId: payload.workspaceId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response;
  }
);

const containersAdapter = createEntityAdapter({});

export const { selectAll: selectSupports, selectById: selectProjectById } =
  containersAdapter.getSelectors((state) => state.supports);

const supportsSlice = createSlice({
  name: "supports",
  initialState: [],
  reducers: {},
  extraReducers: {
    // [getUsers.fulfilled]: containersAdapter.setAll,
    [getSupports.fulfilled]: (state, action) => action.payload.data,
    [deleteSupport.fulfilled]: (state, action) => {
      console.log('action payload on delete support:', action);
      return state.filter((item) => item.id !== action.meta.arg.id)
    },
    [deleteSupport.rejected]: (state, action) => {
      console.log('action payload on delete support errorra futott:', action);
    }
    // [getUsers.fulfilled]: (state, action) => containersAdapter.setAll(action.payload.data),
    // [removeUsers.fulfilled]: (state, action) => containersAdapter.removeMany(state, action.payload),
  },
});

export const { setSupportsSearchText } = supportsSlice.actions;

export default supportsSlice.reducer;
