import { combineReducers } from '@reduxjs/toolkit'
import companies from '../pages/companies/store/companiesSlice'
import users from '../pages/users/store/usersSlice'
import supports from '../pages/support-tables/main-support-table/store/supportsSlice'
import materials from '../pages/support-tables/materials/store/materialsSlice'
import accessGroups from '../pages/access-groups/store/access-group-slice'
import locations from '../pages/locations/store/locations-slice'
import auth from '../pages/login/store/loginSlice'
// import i18n from './i18nSlice'

const createReducer = asyncReducers => (state, action) => {
	const combinedReducer = combineReducers({
		auth,
//		i18n,
		companies,
		users,
		supports,
		materials,
		accessGroups,
		locations,
		...asyncReducers,
	})

	/*
	Reset the redux store when user logged out
	 */
	if (action.type === 'auth/user/userLoggedOut') {
		state = undefined
	}

	return combinedReducer(state, action)
}

export default createReducer
