import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { deleteSupport, getSupports } from "./store/supportsSlice";
import { Link } from "react-router-dom";
import DataTable from "../../../components/datatable";
import BreadCrumb from "../../../components/breadcrumb";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

export default function Supports() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const supportsData = useSelector((state) => state.supports);
  const workspaceId = useSelector((state) => state.auth.workspaceId);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("supports list useEffect meghívódik!");
    dispatch(getSupports({ workspaceId: workspaceId })).then(
      (res) => console.log("support resp:", res),
      setLoading(false)
    );
    // setLoading(false); <-- ez már nem kell OK, mert itt van felette igen látom
  }, [dispatch]);

  const handleDelete = ({ id }) => {
    dispatch(deleteSupport({ id: id }))
      .then(() => console.log("sikeresen törölve"))
      .catch((e) => console.log(`hiba történt a delete során:`, e));
  };

  const handleCopy = ({ id }) => {
    navigate(`/supports/new/${id}`);
  };

  if (loading) return <>Loading...</>;

  const supportsTransformedData = supportsData.map((item) => ({
    ...item,
    id: {
      link: `/supports/${item.id}`,
      column: item.id,
    },
    tenDigit: {
      link: `/supports/${item.id}`,
      column: item.tenDigit,
    },
    descEn: {
      link: `/supports/${item.id}`,
      column: item.descEn,
    },
    descHu: {
      link: `/supports/${item.id}`,
      column: item.descHu,
    },
    materialEn: {
      link: `/supports/${item.id}`,
      column: item.materialEn,
    },
    materialHu: {
      link: `/supports/${item.id}`,
      column: item.materialHu,
    },
    duty: {
      link: `/supports/${item.id}`,
      column: item.duty,
    },
    olafPrice: {
      link: `/supports/${item.id}`,
      column: item.olafPrice,
    },
    category: {
      link: `/supports/${item.id}`,
      column: item.category,
    },
    copy: {
      html: <Button onClick={() => handleCopy({ id: item.id })}>Copy</Button>,
    },
    del: {
      html: (
        <Button onClick={() => handleDelete({ id: item.id })}>Delete</Button>
      ),
    },
    createdAt: dayjs(item.createdAt).format("YYYY-MM-DD"),
  }));

  const tableColumns = [
    {
      columnTitle: "ID",
      dataKey: ["id"],
      canOrder: true,
    },
    {
      columnTitle: "HSCode",
      dataKey: ["tenDigit"],
      canOrder: true,
    },
    {
      columnTitle: "desc. En",
      dataKey: ["descEn"],
      canOrder: true,
    },
    {
      columnTitle: "desc. Hu",
      dataKey: ["descHu"],
      canOrder: true,
    },
    {
      columnTitle: "mat. En",
      dataKey: ["materialEn"],
      canOrder: true,
    },
    {
      columnTitle: "mat. Hu",
      dataKey: ["materialHu"],
      canOrder: true,
    },
    {
      columnTitle: "Duty tax",
      dataKey: ["duty"],
      canOrder: true,
    },
    {
      columnTitle: "Olaf price (EUR)",
      dataKey: ["olafPrice"],
      canOrder: true,
    },
    {
      columnTitle: "Category",
      dataKey: ["category"],
      canOrder: true,
    },
    {
      columnTitle: "COPY",
      dataKey: ["copy"],
      canOrder: false,
    },
    {
      columnTitle: "DEL",
      dataKey: ["del"],
      canOrder: false,
    },
  ];

  return (
    <>
      {/* BEGIN #content  */}
      <div id="content" className="app-content">
        <BreadCrumb />

        <h1 className="page-header">Supports</h1>
        <hr className="mb-4" />

        <div id="basicTable" className="mb-5">
          <div className="card">
            <div className="card-body">
              <div className="border-bottom">
                <div className="row align-items-center">
                  <div className="col-md-12 text-end">
                    <div className="row justify-content-end mb-2">
                      <div className="col-auto">
                        <select
                          name="filters"
                          id="filters"
                          className="form-select"
                        >
                          <option value="">Quickfilter</option>
                          $filters;
                        </select>
                      </div>
                      <div className="col-auto">
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          data-bs-toggle="modal"
                          data-bs-target="#modal_settings"
                        >
                          <i className="fa-solid fa-gear"></i> View settings
                        </button>
                      </div>
                      <div className="col-auto">
                        <Link
                          to="/supports/new"
                          className="btn btn-outline-theme"
                        >
                          <i className="fa-solid fa-user"></i> New data
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <DataTable
                  columns={tableColumns}
                  data={supportsTransformedData}
                />
              </div>
            </div>

            <div className="card-arrow">
              <div className="card-arrow-top-left"></div>
              <div className="card-arrow-top-right"></div>
              <div className="card-arrow-bottom-left"></div>
              <div className="card-arrow-bottom-right"></div>
            </div>
          </div>
        </div>
      </div>
      {/* END #content */}
    </>
  );
}

export { default as SupportCreate } from "./SupportCreate";
