import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import axios from 'axios'

export const getStats = createAsyncThunk('ai-stats/getStats', async ({ dateFrom, dateTo }) => {
	const response = await axios.post(`${process.env.REACT_APP_MASTERDB_API_ENDPOINT}/api/v1/container-recommendation/stats`, {
		dateFrom: dateFrom,
		dateTo: dateTo,
	}, {
		headers: {
			'Content-Type': 'application/json',
		},
		withcredentials: true,
	})

	return response
})

export const getCompanyById = createAsyncThunk('companies/getCompanyById', async ({ companyId }) => {
	const response = await axios.get(`${process.env.REACT_APP_APIURL}/companies/${companyId}`, {
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return response;
})

export const createCompany = createAsyncThunk('companies/createCompany', async (payload) => {
	const response = await axios.post(`${process.env.REACT_APP_APIURL}/companies`, {
		address: payload.street,
		bankaccount: payload.bankAccount,
		cityId: payload.city,
		companyTypeId: payload.companyType,
		countryId: payload.country,
		email: payload.centralEmail,
		euvat: payload.euVat,
		name: payload.companyName,
		notes: payload.notes,
		opened: payload.openingHours,
		phone: payload.centralPhone,
		regnumber: payload.regNumber,
		signature: null,
		snumber: payload.houseNumber,
		stamp: null,
		website: payload.website,
		workspaceId: payload.workspaceId,
		zip: payload.zipCode,
	}, {
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return response;
})

export const updateCompany = createAsyncThunk('companies/updateCompany', async (payload) => {
	const response = await axios.put(`${process.env.REACT_APP_APIURL}/companies/${payload.companyId}`, {
		address: payload.street,
		bankaccount: payload.bankAccount,
		cityId: payload.city,
		companyTypeId: payload.companyType,
		countryId: payload.country,
		email: payload.centralEmail,
		euvat: payload.euVat,
		name: payload.companyName,
		notes: payload.notes,
		opened: payload.openingHours,
		phone: payload.centralPhone,
		regnumber: payload.regNumber,
		signature: null,
		snumber: payload.houseNumber,
		stamp: null,
		website: payload.website,
		workspaceId: payload.workspaceId,
		zip: payload.zipCode,
	}, {
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return response;
})
/*
export const removeCompanies = createAsyncThunk(
	'eCommerceApp/companies/removeCompanies',
	async (projectIds, { dispatch, getState }) => {
		await axios.post('/api/e-commerce-app/remove-companies', { projectIds })

		return projectIds
	}
)
*/
const companiesAdapter = createEntityAdapter({})

export const { selectAll: selectCompanies, selectById: selectProjectById } = companiesAdapter.getSelectors(
	state => state.companies
)

const companiesSlice = createSlice({
	name: 'companies',
	initialState: null,
	reducers: {},
})

export const { setCompaniesSearchText } = companiesSlice.actions

export default companiesSlice.reducer
