import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../../../components/breadcrumb";
import dayjs from 'dayjs';
import Button from "../../../components/button";
import SettingsIcon from '@mui/icons-material/Settings';
import GetAppIcon from '@mui/icons-material/GetApp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { FormControl, InputLabel, MenuItem, Stack } from "@mui/material";
import DataTable from "../../../components/datatable";
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";
import MuiDialog from "../../../components/dialog/Dialog";
import { companyType } from "../../../constants/variables";


const tableColumns = [{
  columnTitle: 'ID',
  dataKey: ['id'],
  canOrder: true,
},
{
  columnTitle: 'Company type',
  dataKey: ['companyTypeId'],
  canOrder: true,
},
{
  columnTitle: 'Company name',
  dataKey: ['name'],
  canOrder: true,
},
{
  columnTitle: 'Central email',
  dataKey: ['email'],
  canOrder: true,
},
{
  columnTitle: 'Website',
  dataKey: ['website'],
  canOrder: true,
},
{
  columnTitle: 'Country',
  dataKey: ['countryId'],
  canOrder: true,
},
{
  columnTitle: 'City',
  dataKey: ['cityId'],
  canOrder: true,
},
{
  columnTitle: 'Created',
  dataKey: ['createdAt'],
  canOrder: true,
},];

export default function MasterDbEditorList() {
  const dispatch = useDispatch();
  const companiesData = [];
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const workspaceId = useSelector((state) => state.auth.workspaceId);

  /*
  useEffect(() => {
    console.log("companies list useEffect meghívódik!");
    dispatch(getCompanies({ workspaceId })).then(() => {
      setLoading(false)
    });
  }, [dispatch, workspaceId]);
  */

  if (loading) return null

  const companiesTransformedData = companiesData.map((item) => ({
    ...item,
    id: {
      link: `/companies/${item.id}`,
      column: item.id,
    },
    name: {
      link: `/companies/${item.id}`,
      column: item.name,
    },
    companyTypeId: {
      column: companyType[item.companyTypeId - 1]['name'],
    },
    createdAt: dayjs(item.createdAt).format('YYYY-MM-DD'),
  }));

  console.log(companiesTransformedData);

  return (
    <>
      {/* BEGIN #content */}
      <div id="content" className="app-content">
        <BreadCrumb />
        <h1 className="page-header">Master DB Editor</h1>
        <hr className="mb-4" />

        <div id="basicTable" className="mb-5">
          <div className="card">
            <div className="card-body">
              <div className="border-bottom">
                <div className="row align-items-center">
                  <div className="col-md-12 text-end">
                    <div className="row justify-content-end mb-2">
                      <div className="col-auto flex justify-between flex-row-reverse">
                        <Stack direction="row" spacing={2} justifyContent="flex-end">

                          <Button label="View settings" startIcon={<SettingsIcon />} textcolor="#ff9f0c" type="button" onClick={() => {
                              console.log('clicked')
                              setDialogOpen(true)
                            }}
                          />

                          <Link to="/companies/new">
                            <Button variant="outlined" label="New company" startIcon={<PersonAddIcon />} type="button"
                            />
                          </Link>

                          <Button label="Export" startIcon={<GetAppIcon />} type="button" onClick={() => {
                              console.log('clicked')
                            }}
                          />
                        </Stack>
                        <Stack>
                          <div className="itemCounter">{companiesData.length}</div>
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <DataTable columns={tableColumns} data={companiesTransformedData} />
              </div>
            </div>

            <div className="card-arrow">
              <div className="card-arrow-top-left"></div>
              <div className="card-arrow-top-right"></div>
              <div className="card-arrow-bottom-left"></div>
              <div className="card-arrow-bottom-right"></div>
            </div>
          </div>
        </div>
      </div>
      {/* END #content */}
      <MuiDialog 
        open={dialogOpen}
				handleClose={() => {setDialogOpen(false)}}
        title="View settings" 
      />
    </>
  );
}
