import { connect } from 'react-redux'
// import { matchRoutes } from 'react-router-config'
import { useContext, useEffect, useState } from 'react';
import withRouter from '../withrouter';
import AppContext from '../../AppContext';
import { useLocation, useNavigate } from 'react-router';
import { signInWithToken } from '../jwtservice';

const Authorization = (props) => {
    console.log('AUTHORIZATION PROPS:', props);
    const [redirectUrl, setRedirectUrl] = useState('/');
    const [accessGranted, setAccessGranted] = useState(true);
    const [loading, setLoading] = useState(true);
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const { routes } = context;
    const location = useLocation();
    const { pathname, state } = location

    console.log('LOC:', location);

    useEffect(() => {
        console.log('LEFUT redirectRoute')

        const redirectRoute = async () => {
        
            const token = window.localStorage.getItem('jwt_access_token');
    
            console.log('TOKENNN:', token);
            /*
            User is guest
            Redirect to Login Page
            */
            // if (!userRole || userRole.length === 0) {
            if (!token && pathname !== '/login') {
                console.log('NINCS TOKENNN');
                setLoading(false);
                navigate('/login')
            } else {
                try {
                    const tokenCheck = await jwtCheck(token);
                    console.log('tokenCheck', tokenCheck);
                    console.log('AUTHZ NVIGATION:');
                    setLoading(false);
                    navigate(pathname)
                } catch (e) {
                    setLoading(false);
                    navigate('/login')
                }
            }
            
        }

        redirectRoute();

    }, [pathname, navigate])

    

    const jwtCheck = (token) =>
		new Promise((resolve, reject) => {
			/**
			 * Sign in and retrieve user data from Api
			 */
			signInWithToken(token).then(response => {
                console.log('signInWithToken response', response);
				resolve()
			})
			.catch(error => {
                console.log('signInWithToken catch', error);
				reject(error);
			})
	    })

    if (loading) return null;

    return accessGranted ? <>{ props.children }</> : null
}

function mapStateToProps({ auth }) {
	return {
		// userRole: auth.user.role,
	}
}


export default withRouter(connect(mapStateToProps)(Authorization))
// Authorization.contextType = AppContext