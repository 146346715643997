import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import BreadCrumb from "../../components/breadcrumb";
import CardBox from "../../components/card-box";
import { Formik, Form } from "formik";
import TextInput from "../../components/text-input";
import Button from "../../components/button";
import withRouter from "../../services/withrouter";
import { getCompanyById, updateCompany } from "./store/companiesSlice";
import MenuItem from "@mui/material/MenuItem";
import BaseSelect from "../../components/select/Select";
import { cities } from "../../constants/variables";
import { InputLabel } from "@mui/material";

const CompanyEdit = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState(null);
  const { companyId } = props.params;
  const workspaceId = useSelector((state) => state.auth.workspaceId);

  useEffect(() => {
    console.log("company edit useEffect meghívódik!");

    const fetchData = async () => {
      try {
        // Elindítjuk az összes aszinkron műveletet egyidejűleg Promise.all segítségével
        const [data1] = await Promise.all([
          dispatch(getCompanyById({ companyId })),
        ]);

        setCompanyData(data1.payload.data);
        setLoading(false);
      } catch (error) {
        console.error("Hiba az API kérések során", error);
      }
    };

    fetchData();
  }, [dispatch, workspaceId, companyId]);

  if (loading) return null;

  const companyTypes = [
    { id: 1, name: "AEO" },
    { id: 2, name: "EU buyer" },
    { id: 3, name: "EU Importing" },
    { id: 4, name: "Exporting" },
    { id: 5, name: "Forwarding" },
    { id: 6, name: "Haulier" },
    { id: 7, name: "Trade agent" },
  ];

  const initialValues = {
    companyType: companyData?.companyTypeId || "",
    companyName: companyData?.name || "",
    centralEmail: companyData?.email || "",
    centralPhone: companyData?.phone || "",
    website: companyData?.website || "",
    country: companyData?.countryId || "",
    city: companyData?.cityId || "",
    zipCode: companyData?.zip || "",
    street: companyData?.address || "",
    houseNumber: companyData?.snumber || "",
    openingHours: companyData?.opened || "",
    euVat: companyData?.euvat || "",
    regNumber: companyData?.regnumber || "",
    bankAccount: companyData?.bankaccount || "",
    notes: companyData?.notes || "",
    companyDirector: "",
  };

  return (
    <>
      {/* BEGIN #content  */}
      <div id="content" className="app-content">
        <BreadCrumb />
        <h1 className="page-header">Edit company</h1>
        <hr className="mb-4" />

        <div id="basicTable" className="mb-5">
          <div className="row flex justify-between">
            <div className="w-[49%]">
              <CardBox
                title="Company data"
                bodyElement={
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      console.log(values);
                      dispatch(
                        updateCompany({ ...values, workspaceId, companyId })
                      );
                    }}
                  >
                    {(formik) => (
                      <Form noValidate autoComplete="off">
                        <div className="relative">
                          <BaseSelect
                            fullWidth
                            variant="standard"
                            label="Company type"
                            labelId="access-level-select"
                            size="small"
                            sx={{ mb: 3 }}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            onChange={() =>
                              console.log("Company type selected")
                            }
                            defaultValue=""
                            name="companyType"
                          >
                            {companyTypes.map((companyType, idx) => (
                              <MenuItem
                                key={`ag-${idx}`}
                                value={companyType.id}
                              >
                                {companyType.name}
                              </MenuItem>
                            ))}
                          </BaseSelect>
                        </div>
                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Company name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="companyName"
                            />
                          </div>
                        </div>
                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Central email"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              name="centralEmail"
                              type="text"
                              error=""
                            />
                          </div>
                        </div>
                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Central phone"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              name="centralPhone"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Website"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              name="website"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="relative">
                          <BaseSelect
                            fullWidth
                            variant="standard"
                            label="Country"
                            labelId="country-select"
                            size="small"
                            sx={{ mb: 3 }}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            defaultValue=""
                            name="country"
                          >
                            <MenuItem key={`country-1`} value={1}>
                              Hungary
                            </MenuItem>
                            <MenuItem key={`country-2`} value={2}>
                              China
                            </MenuItem>
                          </BaseSelect>
                        </div>

                        <div className="relative">
                          <BaseSelect
                            fullWidth
                            variant="standard"
                            label="City"
                            labelId="city-select"
                            size="small"
                            sx={{ mb: 3 }}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            name="city"
                          >
                            {cities.map((city, idx) => (
                              <MenuItem key={`ag-${idx}`} value={city.id}>
                                {city.name}
                              </MenuItem>
                            ))}
                          </BaseSelect>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"ZIP Code"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              name="zipCode"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Street"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              name="street"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"House number"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              name="houseNumber"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="relative mb-6 flex">
                          <TextInput
                            id="standard-multiline-static"
                            label="Opening hours"
                            multiline
                            name="openingHours"
                            rows={4}
                            variant="standard"
                            fullWidth
                          />
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"EU VAT"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              name="euVat"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Registration number"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              name="regNumber"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Bank account"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              name="bankAccount"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="relative mb-6 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Notes"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              id="notes"
                              multiline
                              name="notes"
                              rows={4}
                              variant="standard"
                              fullWidth
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Company director"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              name="companyDirector"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="text-center">
                          <Button
                            size="sm"
                            label="Save"
                            type="button"
                            onClick={() => {
                              console.log("clicked");
                              formik.submitForm();
                            }}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(CompanyEdit);
