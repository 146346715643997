import React, { useEffect } from "react";
import { createBrowserRouter, useNavigate, useLocation, BrowserRouter as Router, RouterProvider, createBrowserHistory } from "react-router-dom";
import Provider from "react-redux/es/components/Provider";
import store from "./store";
import routes from "./routes";
// import logo from './logo.svg';
import "./App.css";
import Authorization from "./services/authorization";
import AppContext from "./AppContext";
import PageLayout from "./components/page-layout/page-layout";
import { ThemeProvider, createTheme } from "@mui/material";

function App() {
  console.log("app started");
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  const theme = createTheme({
    typography: {
      fontFamily: 'Chakra Petch',
    },
    palette: {
      primary: {
        main: '#ff9f0c',
      },
      secondary: {
        main: '#edf2ff',
      },
    },
    props:{
      MuiButtonBase: {
          TouchRippleProps: {
              classes: {
                  root: {
                    color: '#fff'
                  }
              }
          }
      }
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            textTransform: "none",
            fontFamily: "inherit",
          },
        },
      },
      MuiTouchRipple: {
        // Name of the rule
        child: {
          // Some CSS
          color: "#fff"
        }
      },
      MuiSelect: {
        styleOverrides: {
          standard: {
            borderColor: '#FFFFFF',
          },
          root: {
            color: "#FFFFFF",
            borderColor: "#FFFFFF !important",
            ":before": {
              color: "#FFFFFF",
              borderColor: "ffffff40 !important",
            },
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: "#FFFFFF",
            // borderColor: "#FFFFFF !important",
            ":before": {
              borderColor: "#ffffff40 !important",
            },
            '& .Mui-disabled': {
              color: "#FFFFFF",
            },
          },
        },
        
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: "#FFFFFF",
            borderColor: "#FFFFFF !important",
            ":before": {
              borderColor: "#FFFFFF !important",
            },
            fontSize: 14,
          },
          label: {
            color: "#FFFFFF",
          },
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: "#FFFFFF",
            borderColor: "#FFFFFF",
            transition: (['border-color', 'box-shadow']),
            ":before": {
              borderColor: "#FFFFFF !important",
            },
            "&.Mui-focused fieldset": {
              borderColor: "green !important",
              borderWidth: "1px !important",
            },
            // "&:hover fieldset": {
            //   borderColor: "transparent !important",
            // },
            '& .Mui-focused': {
              borderColor: "#FFFFFF !important",
            },
            "fieldset": {
              borderColor: "rgba(255,255,255,.3) !important",
              transition: 'border-color 0.2s ease-in-out',
            },
          },
          notchedOutline: {
            color: "#FFFFFF",
            borderColor: "rgba(255,255,255,.3)",
          },
          label: {
            color: "#FFFFFF",
          },
          input: {
            padding: 9,
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#FFFFFF",
            fontFamily: "inherit",
            disabled: {
              color: "#FFFFFF",
            },
            '& .Mui-disabled': {
              color: "#FFFFFF",
            },
            fontSize: 14,
          },
          
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "#FFFFFF",
            fontFamily: "inherit",
            disabled: {
              color: "#FFFFFF",
            },
            '& .Mui-disabled': {
              color: "#FFFFFF",
            }
          },
          '& .Mui-disabled': {
            color: "#FFFFFF",
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            color: "#FFFFFF",
            fontFamily: "inherit",
            disabled: {
              color: "#FFFFFF",
            },
            '& .Mui-disabled': {
              color: "#FFFFFF",
            }
          },
          '& .Mui-disabled': {
            color: "#FFFFFF",
          }
        }
      },
    },
  });

  return (
    <AppContext.Provider
			value={{
				routes,
			}} 
		>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Authorization>
            <PageLayout />
          </Authorization>
        </ThemeProvider>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;
