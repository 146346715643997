import React, { memo } from "react";
import Sidebar from "../sidebar";

function Layout(props) {
  console.log('Rendering Layout...');

  return (
    <>
    { props.route.layout ? <div className="flex">
        <Sidebar />
        { props.route.children ? props.route.children[0].component : React.cloneElement(props.route.component, { ...props }) }
      </div> : props.route.component }
      
    </>
  );
};

export default memo(Layout);
