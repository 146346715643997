import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import BreadCrumb from "../../../components/breadcrumb";
import CardBox from "../../../components/card-box";
import { Formik, Form } from "formik";
import TextInput from "../../../components/text-input";
import Button from "../../../components/button";
import { getSupportById } from "./store/supportsSlice";
import { editSupport } from "./store/supportsSlice";
import withRouter from "../../../services/withrouter";
import MenuItem from "@mui/material/MenuItem";
import Select from "../../../components/select/Select";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { InputLabel } from "@mui/material";

const SupportEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [supportData, setSupportData] = useState(null);
  const { supportId } = props.params;
  const workspaceId = useSelector((state) => state.auth.workspaceId);
  // const { workspaceId } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tenDigit: supportData?.tenDigit || "1234",
    },
  });

  useEffect(() => {
    console.log("support data useEffect meghívódik!");

    const fetchData = async () => {
      try {
        const [data] = await Promise.all([
          dispatch(getSupportById({ supportId })),
        ]);

        setSupportData(data.payload.data);
        setLoading(false);
      } catch (error) {
        console.error("Hiba az API kérések során", error);
      }
    };

    fetchData();
  }, [dispatch, workspaceId, supportId]);

  const handleSubmitData = (data) => {
    dispatch(
      editSupport({
        ...data,
        id: supportId,
        workspaceId: workspaceId,
      })
    )
      .then((res) => {
        navigate("/supports", { replace: true });
      })
      .catch((e) => console.log(e));
  };

  if (loading) return null;

  const initialValues = {
    tenDigit: supportData.tenDigit || "",    
    tenDigitName: supportData.tenDigitName || "",
    descEn: supportData.descEn || "",
    descHu: supportData.descHu || "",
    materialEn: supportData.materialEn || "",
    materialHu: supportData.materialHu || "",
    duty: supportData.duty || "",
    dutyTaric: supportData.dutyTaric || "",
    olafPrice: supportData.olafPrice || "",
    category: supportData.category || "",
    sectionNum: supportData.sectionNum || "",
    sectionName: supportData.sectionName || "",
    chapterNum: supportData.chapterNum || "",
    chapterName: supportData.chapterName || "",
    subChapterName: supportData.subChapterName || "",
    fourDigit: supportData.fourDigit || "",
    fourDigitName: supportData.fourDigitName || "",
    sixDigit: supportData.sixDigit || "",
    sixDigitName: supportData.sixDigitName || "",
    eightDigit: supportData.eightDigit || "",
    eightDigitName: supportData.eightDigitName || "",
  };
  console.log("support: ", supportData);

  return (
    <>
      {/* BEGIN #content  */}
      <div id="content" className="app-content">
        <BreadCrumb />
        <h1 className="page-header">Edit support</h1>
        <hr className="mb-4" />

        <div id="basicTable" className="mb-5">
          <div className="row flex justify-between">
            <div className="w-[49%]">
              <CardBox
                title="Support data"
                bodyElement={
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      console.log(values);
                      handleSubmitData(values);
                    }}
                  >
                    {(formik) => (
                      <Form noValidate autoComplete="off">
                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"HSCode"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="tenDigit"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"HSCode name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="tenDigitName"
                            />
                          </div>
                        </div>                        

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"English description"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="descEn"
                            />
                          </div>
                        </div>                                                

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Hungarian description"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="descHu"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"English material name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="materialEn"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Hungarian material name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="materialHu"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Duty Tax"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="duty"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Duty Taric"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="dutyTaric"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Olaf price"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="olafPrice"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Category"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <Select
                              fullWidth
                              variant="standard"
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              defaultValue=''
                              name="category"
                            >
                                <MenuItem key={"blacklist"} value={"blacklist"}>{"blacklist"}</MenuItem>
                                <MenuItem key={"risky"} value={"risky"}>{"risky"}</MenuItem>
                                <MenuItem key={"whitelist"} value={"whitelist"}>{"whitelist"}</MenuItem>
                                <MenuItem key={"normal"} value={"normal"}>{"normal"}</MenuItem>
                            </Select>
                          </div>  
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Section number"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="sectionNum"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Section name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="sectionName"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Chapter number"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="chapterNum"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Chapter name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="chapterName"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Subchapter name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="subChapterName"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"4Digit"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="fourDigit"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"4Digit name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="fourDigitName"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"6Digit"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="sixDigit"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"6Digit name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="sixDigitName"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"8Digit"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="eightDigit"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"8Digit name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="eightDigitName"
                            />
                          </div>
                        </div>

                        <div className="text-center">
                          <Button
                            size="sm"
                            label="Save"
                            type="button"
                            onClick={() => {
                              console.log("clicked");
                              formik.submitForm();
                            }}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                }
              />
              <div>                
{/*}               <form onSubmit={handleSubmit((data) => console.log(data))}>*/}
                  {/* register your input into the hook by invoking the "register" function */}
{/*</div>]                  <TextField
                    {...register("tenDigit")}
                    fullWidth
                    variant="standard"
                    label={"HSCode"}
                    type="text"
                  />

                  <input type="submit" />
              </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SupportEdit);
