import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import axios from 'axios'

export const getAccessGroups = createAsyncThunk('users/getAccessGroups', async ({ workspaceId }) => {
	const response = await axios.get(`${process.env.REACT_APP_APIURL}/access-group/list/${workspaceId}`, {
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return response;
})

export const getAccessGroupById = createAsyncThunk('users/getAccessGroupById', async ({ id }) => {
	const response = await axios.get(`${process.env.REACT_APP_APIURL}/access-group/${id}`, {
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return response;
})

const containersAdapter = createEntityAdapter({})

export const { selectAll: selectUsers, selectById: selectProjectById } = containersAdapter.getSelectors(
	state => state.users
)

const containersSlice = createSlice({
	name: 'accessGroups',
	initialState: null,
	reducers: {},
	extraReducers: {
		// [getAccessGroups.fulfilled]: containersAdapter.setAll,
		[getAccessGroups.fulfilled]: (state, action) => action.payload.data,
		// [getAccessGroups.fulfilled]: (state, action) => containersAdapter.setAll(action.payload.data),
		// [removeUsers.fulfilled]: (state, action) => containersAdapter.removeMany(state, action.payload),
	},
})

export const { setUsersSearchText } = containersSlice.actions

export default containersSlice.reducer
