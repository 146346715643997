export default function CardBox(props) {
    return (
        <div className={`card ${props.className}`}>
            <div className="card-header d-flex align-items-center bg-white/10">
                <span className="flex-grow-1 fw-400 text-sm">{props.title}</span>
            </div>

            <div className="card-body">
                {props?.bodyElement}
            </div>

            <div className="card-arrow">
                <div className="card-arrow-top-left"></div>
                <div className="card-arrow-top-right"></div>
                <div className="card-arrow-bottom-left"></div>
                <div className="card-arrow-bottom-right"></div>
            </div>
        </div>
    );
}