import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import BreadCrumb from "../../../components/breadcrumb";
import CardBox from "../../../components/card-box";
import { Formik, Form } from "formik";
import TextInput from "../../../components/text-input";
import Button from "../../../components/button";
import Switch from "../../../components/Switch";
import { getMaterialById } from "./store/materialsSlice";
import { editMaterial } from "./store/materialsSlice";
import withRouter from "../../../services/withrouter";
import MenuItem from "@mui/material/MenuItem";
import Select from "../../../components/select/Select";
import { InputLabel } from "@mui/material";
import { useNavigate } from "react-router";

const MaterialEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [materialData, setMaterialData] = useState(null);
  const { materialId } = props.params;
  const workspaceId = useSelector((state) => state.auth.workspaceId);
  // const { workspaceId } = useSelector((state) => state.auth);

  useEffect(() => {
    console.log("material data useEffect meghívódik!");

    const fetchData = async () => {
      try {
        const [data] = await Promise.all([
          dispatch(getMaterialById({ materialId })),
        ]);

        setMaterialData(data.payload.data);
        setLoading(false);
      } catch (error) {
        console.error("Hiba az API kérések során", error);
      }
    };

    fetchData();
  }, [dispatch, workspaceId, materialId]);

  const handleSubmitData = (data) => {
    dispatch(
      editMaterial({
        ...data,
        id: materialId,
        workspaceId: workspaceId,
      })
    )
      .then((res) => {
        navigate("/materials", { replace: true });
      })
      .catch((e) => console.log(e));
  };

  if (loading) return null;

  const initialValues = {
    en: materialData.en || "",
    hu: materialData.hu || "",
    id: materialData.id || null,
  };
  console.log("material: ", materialData);

  return (
    <>
      {/* BEGIN #content  */}
      <div id="content" className="app-content">
        <BreadCrumb />
        <h1 className="page-header">Edit material</h1>
        <hr className="mb-4" />

        <div id="basicTable" className="mb-5">
          <div className="row flex justify-between">
            <div className="w-[49%]">
              <CardBox
                title="Material data"
                bodyElement={
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      console.log(values);
                      handleSubmitData(values);
                    }}
                  >
                    {(formik) => (
                      <Form noValidate autoComplete="off">

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"English name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="en"
                            />
                          </div>
                        </div>

                        <div className="relative mb-5 flex">
                          <div className="w-5/12 self-center">
                            <InputLabel>{"Hungarian name"}</InputLabel>
                          </div>
                          <div className="w-7/12">
                            <TextInput
                              fullWidth
                              variant="standard"
                              type="text"
                              name="hu"
                            />
                          </div>
                        </div>

                        <div className="text-center">
                          <Button
                            size="sm"
                            label="Save"
                            type="button"
                            onClick={() => {
                              console.log("clicked");
                              formik.submitForm();
                            }}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(MaterialEdit);
