import React from "react";
import { useField, useFormikContext } from "formik";
// import { DateTimePicker, DatePicker, TimePicker } from '@material-ui/pickers'
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// eslint-disable-next-line import/prefer-default-export
const DateTimePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  if (props && props?.onlyDate) {
    delete props.onlyDate;
    return (
      <DesktopDatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />
    );
  }
};

export default DateTimePickerField;
