import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import axios from 'axios'

export const getLocations = createAsyncThunk('locations/getLocations', async ({ workspaceId }) => {
	const response = await axios.get(`${process.env.REACT_APP_APIURL}/locations/list/${workspaceId}`, {
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return response;
})

export const getLocationById = createAsyncThunk('locations/getLocationById', async ({ id }) => {
	const response = await axios.get(`${process.env.REACT_APP_APIURL}/locations/${id}`, {
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return response;
})

const containersAdapter = createEntityAdapter({})

export const { selectAll: selectLocations, selectById: selectProjectById } = containersAdapter.getSelectors(
	state => state.locations
)

const containersSlice = createSlice({
	name: 'locations',
	initialState: null,
	reducers: {},
	extraReducers: {
		// [getLocations.fulfilled]: containersAdapter.setAll,
		[getLocations.fulfilled]: (state, action) => action.payload.data,
		// [getLocations.fulfilled]: (state, action) => containersAdapter.setAll(action.payload.data),
		// [removeUsers.fulfilled]: (state, action) => containersAdapter.removeMany(state, action.payload),
	},
})

export const { setUsersSearchText } = containersSlice.actions

export default containersSlice.reducer
