import { FormControl, FormHelperText, InputLabel } from "@mui/material"
import BaseSelect from '@mui/material/Select'
import { withFormikField } from "../../shared-components"
import clsx from "clsx"

const Select = ({ label, labelId, className, helperText, fullWidth, variant, sx, ...props }) => {

	return (
		<FormControl 
            className={clsx(className)} 
            fullWidth={fullWidth} 
            variant={variant} 
            sx={sx} 
        >
			<InputLabel id={labelId}>{label}</InputLabel>
			<BaseSelect {...props} labelId={labelId} />
			{helperText && <FormHelperText id={`${labelId}-helper`}>{helperText}</FormHelperText>}
		</FormControl>
	)
}

export default withFormikField(Select)