import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { getUsers } from "./store/usersSlice";
import { Link } from "react-router-dom";

export default function Locations(props) {
  const dispatch = useDispatch();
  const locationsData = useSelector((state) => state.locations);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(props)
    document.title = props.title;
  }, [props]);

  useEffect(() => {
    console.log("locations list useEffect meghívódik!");
    // dispatch(getUsers()).then(() => setLoading(false));
    setLoading(false);
  }, [dispatch]);

  if (loading) return <>Loading...</>;

  console.log("locationsData:", locationsData);

  return (
    <>
      {/* BEGIN #content  */}
      <div id="content" className="app-content">
        <ul className="breadcrumb">
          <Link to="/" className="breadcrumb-item">
            <>
              <span>Dashboard</span>
            </>
          </Link>
          <li className="breadcrumb-item active">Locations</li>
        </ul>
        <h1 className="page-header">Locations</h1>
        <hr className="mb-4" />

        <div id="basicTable" className="mb-5">
          <div className="card">
            <div className="card-body">
              <div className="border-bottom">
                <div className="row align-items-center">
                  <div className="col-md-12 text-end">
                    <div className="row justify-content-end mb-2">
                      <div className="col-auto">
                        <select
                          name="filters"
                          id="filters"
                          className="form-select"
                        >
                          <option value="">Quickfilter</option>
                          $filters;
                        </select>
                      </div>
                      <div className="col-auto">
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          data-bs-toggle="modal"
                          data-bs-target="#modal_settings"
                        >
                          <i className="fa-solid fa-gear"></i> View settings
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                {/*<table className="table table-hover">
              <thead>
                <tr>
                    <td>
                  <form
                    id="searchform"
                    name="searchform"
                    className=""
                    method="post"
                    action="/companies/view/search"
                  >
                    $theader
                  </form>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>$companies</td>
                </tr>
              </tbody>
              </table> 
                <EnhancedTable data={locationsData} /> */}
              </div>
            </div>

            <div className="card-arrow">
              <div className="card-arrow-top-left"></div>
              <div className="card-arrow-top-right"></div>
              <div className="card-arrow-bottom-left"></div>
              <div className="card-arrow-bottom-right"></div>
            </div>
          </div>
        </div>
      </div>
      {/* END #content */}
    </>
  );
}
