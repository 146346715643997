import { useContext, useRef, useCallback, Component } from "react"
import AppContext from "../../AppContext"
import { useLocation, matchRoutes, Route, useNavigate } from "react-router"
import Layout from "../layout"
import withRouter from "../../services/withrouter"

function PageLayout(props) {
	// const settings = useSelector(({ fuse }) => fuse.settings.current)
	// const defaultSettings = useSelector(({ fuse }) => fuse.settings.defaults)

	const appContext = useContext(AppContext)
	const { routes } = appContext
	const location = useLocation()
	const navigate = useNavigate();
	const { pathname } = location
	const matched = matchRoutes(routes, pathname)[0]
	const newSettings = useRef(null)

    const shouldAwaitRender = useCallback(() => {
		console.log('shouldAwaitRender inner');
		let _newSettings
		/**
		 * On Path changed
		 */
		// if (prevPathname !== pathname) {
		if (matched && matched.route) {
			/**
			 * if matched route has settings
			 */

			const routeSettings = matched

			_newSettings = routeSettings
		}

		// if (!_.isEqual(newSettings.current, _newSettings)) {
			newSettings.current = _newSettings
		// }
	}, [matched])

	shouldAwaitRender()

	// console.log(newSettings.current);
	const isFunctionComponent = typeof newSettings.current.route.component === 'function' && !Component.prototype.render;
	console.log('isFunctionComponent', isFunctionComponent);

	if (isFunctionComponent) {
		console.log('isFunctionComponentre FUT');
		newSettings.current.route.component({ navigate });
		return <></>
	} else {
		console.log('LAYOUTRA FUT');
		return <Layout {...newSettings.current} />
	}
}

export default withRouter(PageLayout);