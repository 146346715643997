import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import axios from 'axios'

export const getContainers = createAsyncThunk('eCommerceApp/containers/getContainers', async () => {
	// const token = window.localStorage.getItem('jwt_access_token')
	const response = await axios.get(`${process.env.REACT_APP_APIURL}/containers`, {
		headers: {
			// Authorization: `Bearer ${token}`,
			// "Content-Type": 'application/x-www-form-urlencoded;charset=UTF-8',
			'Content-Type': 'application/json',
		},
		withCredentials: true,
	})

	return response.data
})

const containersAdapter = createEntityAdapter({})

export const { selectAll: selectContainers, selectById: selectProjectById } = containersAdapter.getSelectors(
	state => state.containers
)

const containersSlice = createSlice({
	name: 'containers',
	initialState: false,
	reducers: {},
	extraReducers: {
		[getContainers.fulfilled]: containersAdapter.setAll,
		// [getContainers.fulfilled]: (state, action) => containersAdapter.setAll(state, action.payload.data),
		// [removeContainers.fulfilled]: (state, action) => containersAdapter.removeMany(state, action.payload),
	},
})

export const { setContainersSearchText } = containersSlice.actions

export default containersSlice.reducer
