import Button from '@mui/material/Button';

export default function MuiButton(props) {
    return (
        <Button variant="contained" {...props}>{props.label}</Button>
    )
    /*
    return (
        <TERipple rippleColor="light">
            <button
                {...props}
                className={`inline-block rounded border border-[#ff9f0c] bg-transparent px-7 pb-2 pt-2 text-sm font-medium  leading-normal ${`text-[${props.textcolor}]`} transition duration-200 ease-in-out hover:bg-[#ff9f0c] focus:outline-none focus:ring-0`}
                data-te-ripple-init
                data-te-ripple-color="light"
            >
                {props.label}
            </button>
        </TERipple>
    )
    */
}