import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import BreadCrumb from "../../components/breadcrumb";
import CardBox from "../../components/card-box";
import { Formik, Form } from "formik";
import TextInput from "../../components/text-input";
import Button from "../../components/button";
import Switch from "../../components/Switch";
import { getUserById } from "./store/usersSlice";
import withRouter from "../../services/withrouter";
import { getCompanies } from "../companies/store/companiesSlice";
import MenuItem from "@mui/material/MenuItem";
import { getAccessGroups } from "../access-groups/store/access-group-slice";
import { getLocations } from "../locations/store/locations-slice";
import Select from "../../components/select/Select";

const UserEdit = (props) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const { userId } = props.params;
    const companiesData = useSelector((state) => state.companies);
    const accessGroups = useSelector((state) => state.accessGroups);
    const locations = useSelector((state) => state.locations);
    const workspaceId = useSelector((state) => state.auth.workspaceId);

    useEffect(() => {
      console.log("user data useEffect meghívódik!");

      const fetchData = async () => {
        try {
          // Elindítjuk az összes aszinkron műveletet egyidejűleg Promise.all segítségével
          const [data1, data2] = await Promise.all([
            dispatch(getUserById({ userId })),
            dispatch(getCompanies({ workspaceId })),
            dispatch(getAccessGroups({ workspaceId })),
            dispatch(getLocations({ workspaceId })),
          ]);
  
          setUserData(data1.payload.data)
          setLoading(false)
        } catch (error) {
          console.error('Hiba az API kérések során', error);
        }
      };
  
      fetchData();
      
    }, [dispatch, userId, workspaceId]);

    if (loading) return null

    const initialValues = {
      accessGroupId: userData.accessGroupId || "",
      company: userData.company || "",
      country: userData.country || "",
      email: userData.email || null,
      firstName: userData.firstName || null,
      lastName: userData.lastName || null,
      location: userData.location || "",
      notes: userData.notes || null,
      phone: userData.phone || null,
      id: userData.id || null,
    };

    return (
        <>
          {/* BEGIN #content  */}
          <div id="content" className="app-content">
            <BreadCrumb />
            <h1 className="page-header">Edit user</h1>
            <hr className="mb-4" />
    
            <div id="basicTable" className="mb-5">
              <div className="row flex justify-between">
                <div className="w-[49%]">
                  <CardBox title="User data" bodyElement={
                    <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      console.log(values);
                    }}
                    >
                      {(formik) => (
                        <Form noValidate autoComplete="off">
                          <div className="relative mb-6">
                            <TextInput 
                              fullWidth
                              disabled 
                              variant="standard" 
                              label={"ID"} 
                              type="text" 
                              name="id"
                              sx={{"& .MuiInputBase-root.Mui-disabled": {
                                color: "#fff"
                              },
                              "& .MuiFormLabel-root.Mui-disabled": {
                                  color: "#fff"
                              },}} 
                            />
                          </div>
                          <div className="relative mb-6">
                            <TextInput fullWidth variant="standard" label={"Firstname"} type="text" name="firstName" />
                          </div>
                          <div className="relative mb-6">
                            <TextInput fullWidth variant="standard" label={"Lastname"} name="lastName" type="text" error="" />
                          </div>
                          <div className="relative mb-6">
                            <TextInput fullWidth variant="standard" label={"Email"} name="email" type="text" />
                          </div>
                          <div className="relative mb-6">
                            <TextInput fullWidth variant="standard" label={"Phone"} name="phone" type="text" />
                          </div>
                          <div className="relative mb-6">
                            <TextInput fullWidth variant="standard" label={"Access level"} name="accessLevel" type="text" />
                          </div>
                          <div className="relative mb-6">
                            <TextInput fullWidth variant="standard" label={"Created"} name="created" type="text" />
                          </div>
                          <div className="relative mb-6">
                            <TextInput fullWidth variant="standard" label={"Last login"} name="lastLogin" type="text" />
                          </div>
                          <div className="relative">
                            <Select
                              fullWidth
                              variant="standard"
                              label="Access level"
                              labelId="access-level-select"
                              size="small"
                              sx={{ mb: 3 }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              onChange={() => console.log('Access level selected')}
                              defaultValue=''
                              name="accessGroupId"
                            >
                              {accessGroups.map((accessGroup, idx) => 
                                <MenuItem key={`ag-${idx}`} value={accessGroup.id}>{accessGroup.groupName}</MenuItem>
                              )}
                            </Select>
                          </div>
                          <div className="relative">
                            <Select
                              fullWidth
                              variant="standard"
                              label="Company"
                              labelId="demo-select-small-label"
                              size="small"
                              sx={{ mb: 3 }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              onChange={() => console.log('Company selected')}
                              defaultValue=''
                              name="company"
                            >
                              {companiesData.map((company, idx) => 
                                <MenuItem key={`co-${idx}`} value={company.id}>{company.name}</MenuItem>
                              )}
                            </Select>
                          </div>
                          <div className="relative">
                            <Select
                              fullWidth
                              variant="standard"
                              label="Location"
                              labelId="location-select"
                              size="small"
                              sx={{ mb: 3 }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              onChange={() => console.log('Location selected')}
                              defaultValue=''
                              name="location"
                            >
                              {locations.map((location, idx) => 
                                <MenuItem key={`loc-${idx}`} value={location.id}>{location.name}</MenuItem>
                              )}
                            </Select>
                          </div>
                          <div className="relative">
                            <Select
                              fullWidth
                              variant="standard"
                              label="Country"
                              labelId="country-select"
                              size="small"
                              sx={{ mb: 3 }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              defaultValue=''
                              name="country"
                            >
                              <MenuItem key={`country-1`} value={1}>Hungary</MenuItem>
                              <MenuItem key={`country-2`} value={2}>China</MenuItem>
                            </Select>
                          </div>
                          <div className="relative mb-6">
                            <TextInput
                              id="standard-multiline-static"
                              label="Notes"
                              multiline
                              name="notes"
                              rows={4}
                              defaultValue=""
                              variant="standard"
                              fullWidth
                            />
                          </div>
                          <div className="text-center">
                            <Button size="sm" label="Save" type="button" onClick={() => {
                                console.log('clicked')
                                formik.submitForm()
                              }}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  } />
                  <CardBox className="mt-12" title="Picture" />
                  <CardBox className="mt-12" title="Password" bodyElement={
                    <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      console.log(values);
                    }}
                    >
                      {(formik) => (
                        <Form noValidate autoComplete="off">
                          <div className="relative mb-6" data-te-input-wrapper-init>
                            <TextInput label={"Password"} type="password" name="password" />
                          </div>
                          <div className="relative mb-6" data-te-input-wrapper-init>
                            <TextInput label={"Password again"} name="password2" type="password" />
                          </div>
                          <div className="text-center">
                            <Button label="Save" type="button" onClick={() => {
                                console.log('clicked')
                                formik.submitForm()
                              }}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  }/>
                </div>
                <div className="w-[49%]">
                  <CardBox title="Company access" bodyElement={
                    <Formik
                    initialValues={
                      {all: true,}
                    }
                    onSubmit={(values) => {
                      console.log(values);
                    }}
                    >
                      {(formik) => (
                        <Form noValidate autoComplete="off">
                          <div className="relative mb-6">
                            <Switch label="All company access" name="all" checked={initialValues.all} onChange={formik.handleChange} />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  } />
                </div>
              </div>
                   
            </div>

          </div>
        </>
    );
}

export default withRouter(UserEdit);
