import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, memo } from "react";
import { getUsers } from "./store/usersSlice";
import BreadCrumb from "../../components/breadcrumb";
import DataTable from "../../components/datatable";
import dayjs from 'dayjs';
// import AppContext from "../../AppContext";
// import { useParams } from "react-router";
import Button from "../../components/button";
import SettingsIcon from '@mui/icons-material/Settings';
import GetAppIcon from '@mui/icons-material/GetApp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { FormControl, InputLabel, MenuItem, Stack } from "@mui/material";
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";
import MuiDialog from "../../components/dialog/Dialog";

const QuickFilterSelect = memo(() => {
  console.log(`Rendering QuickFilterSelect`);
  return <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Quickfilter</InputLabel>
          <Select defaultValue='' labelId="demo-select-small-label">
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>;
});

export default function Users(props) {
  const dispatch = useDispatch();
  // const appContext = useContext(AppContext)
  // const params = useParams();
  const usersData = useSelector((state) => state.users)
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  // console.log("props users list:", props);
  // console.log("props users params:", params);
  // console.log("appContext:", appContext);

  useEffect(() => {
    console.log("users list useEffect meghívódik!");
    dispatch(getUsers()).then(() => {
      setLoading(false)
    });
  }, [dispatch]);

  if (loading) return null

  const usersTransformedData = usersData.map((item) => ({
    ...item,
    id: {
      link: `/users/${item.id}`,
      column: item.id,
    },
    firstName: {
      link: `/users/${item.id}`,
      column: item.firstName,
    },
    createdAt: dayjs(item.createdAt).format('YYYY-MM-DD'),
  }));
  
  const tableColumns = [{
    columnTitle: 'ID',
    dataKey: ['id'],
    canOrder: true,
  },
  {
    columnTitle: 'First name',
    dataKey: ['firstName'],
    canOrder: true,
  },
  {
    columnTitle: 'Last name',
    dataKey: ['lastName'],
    canOrder: true,
  },
  {
    columnTitle: 'Email',
    dataKey: ['email'],
    canOrder: true,
  },
  {
    columnTitle: 'Phone',
    dataKey: ['phone'],
    canOrder: true,
  },
  {
    columnTitle: 'Created',
    dataKey: ['createdAt'],
    canOrder: true,
  },];

  const quickFilterData = [
    { text: "One", value: 1 },
    { text: "Two", value: 2 },
    { text: "Three", value: 3 },
    { text: "Four", value: 4 },
    { text: "Five", value: 5 },
    { text: "Six", value: 6 },
    { text: "Seven", value: 7 },
    { text: "Eight", value: 8 },
  ];

  return (
    <>
      {/* BEGIN #content  */}
      <div id="content" className="app-content">
        <BreadCrumb />
        <h1 className="page-header">Users</h1>
        <hr className="mb-4" />

        <div id="basicTable" className="mb-5">
          <div className="card">
            <div className="card-body">
              <div className="border-bottom">
                <div className="row align-items-center">
                  <div className="col-md-12 text-end">
                    <div className="row justify-content-end mb-2">
                      <div className="col-auto">
                      <Stack direction="row" spacing={2} justifyContent="flex-end">

                        <QuickFilterSelect />

                        <Button label="View settings" startIcon={<SettingsIcon />} textcolor="#ff9f0c" type="button" onClick={() => {
                            console.log('clicked')
                            setDialogOpen(true)
                          }}
                        />

                        <Link to="/users/new">
                          <Button variant="outlined" label="New user" startIcon={<PersonAddIcon />} type="button"
                          />
                        </Link>

                        <Button label="Export" startIcon={<GetAppIcon />} type="button" onClick={() => {
                            console.log('clicked')
                          }}
                        />

                      </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <DataTable columns={tableColumns} data={usersTransformedData} />
              </div>
            </div>

            <div className="card-arrow">
              <div className="card-arrow-top-left"></div>
              <div className="card-arrow-top-right"></div>
              <div className="card-arrow-bottom-left"></div>
              <div className="card-arrow-bottom-right"></div>
            </div>
          </div>
        </div>
      </div>
      {/* END #content */}
      <MuiDialog 
        open={dialogOpen}
				handleClose={() => {setDialogOpen(false)}}
        title="View settings" 
      />
    </>
  );
}
